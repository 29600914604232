<template>
  <SurveyFullSurvey />
</template>

<script>
import SurveyFullSurvey from "../components/SurveyFullSurvey.vue";

export default {
  name: "SurveyView",

  components: {
    SurveyFullSurvey,
  },
};
</script>
