<template>
  <v-container>
    <DashboardNavDrawer />
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true" />
    <v-container
      v-if="
        $store.getters.username && $store.getters.username.length && !isLoading
      "
    >
      <v-row>
        <h2>Survey Responses</h2>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="5">
          <Plotly
            :data="responseCounts"
            :layout="responseLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
        <v-col cols="5">
          <Plotly
            :data="awardResponse"
            :layout="awardResponseLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
      </v-row>
      <v-row> <h2>Responses by State/Site</h2> </v-row
      ><v-row>
        <v-col cols="1"></v-col>
        <v-col>
          <Plotly
            :data="allRoundStateHorizontalBar"
            :layout="allRoundStateLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col>
          <Plotly
            :data="currentRoundStateHorizontalBar"
            :layout="currentRoundStateLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <Plotly
            :data="stateResponseBar"
            :layout="stateResponseLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import DashboardNavDrawer from "../components/DashboardNavDrawer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";
import { Plotly } from "vue-plotly";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "DashBoard",
  components: {
    DashboardNavDrawer,
    Loading,
    Plotly,
  },
  data: () => ({
    isLoading: false,
    welcomeByState: [],
    surveyResponseFemale: [],
    surveyResponseByState: [],
    allRoundStateHorizontalBar: null,
    allRoundStateLayout: null,
    currentRoundStateHorizontalBar: null,
    currentRoundStateLayout: null,
    stateResponseBar: null,
    stateResponseLayout: null,
    responseCounts: null,
    responseLayout: null,
    awardResponse: null,
    awardResponseLayout: null,
  }),
  computed: {
    awardResponseCount: function () {
      return this.welcomeByState
        .map((e) => e.responses)
        .reduce((a, c) => a + c, 0);
    },
    awardTotalCount: function () {
      return this.welcomeByState
        .map((e) => e.students)
        .reduce((a, c) => a + c, 0);
    },
    comparisonResponseCount: function () {
      return this.surveyResponseFemale.filter(
        (e) => e.student_status === "comparison"
      )[0].total;
    },
    totalResponseCount: function () {
      return this.surveyResponseFemale.filter(
        (e) => e.student_status === "total"
      )[0].total;
    },
    welcomeByStateAggregated: function () {
      const states = [...new Set(this.welcomeByState.map((obj) => obj.state))];
      let agg = [];
      states.forEach((state) => {
        let responses = this.welcomeByState
          .filter((obj) => obj.state === state)
          .map((obj) => obj.responses)
          .reduce((t, v) => t + v);
        let students = this.welcomeByState
          .filter((obj) => obj.state === state)
          .map((obj) => obj.students)
          .reduce((t, v) => t + v);
        agg.push({
          state: state,
          responses: responses,
          students: students,
          pct: Math.round((responses / students) * 100),
        });
      });

      return agg;
    },
    surveyResponseByStateAggregated: function () {
      const states = [
        ...new Set(this.surveyResponseByState.map((obj) => obj.state)),
      ];
      let agg = [];
      states.forEach((state) => {
        let awardCount = this.surveyResponseByState
          .filter((obj) => obj.state === state)
          .map((obj) => obj.award_count)
          .reduce((t, v) => t + v);
        let comparisonCount = this.surveyResponseByState
          .filter((obj) => obj.state === state)
          .map((obj) => obj.comparison_count)
          .reduce((t, v) => t + v);
        agg.push({
          state: state,
          award_count: awardCount,
          comparison_count: comparisonCount,
        });
      });

      return agg;
    },
  },
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      this.isLoading = true;
      let localData = 0;
      console.log("before localforage promise");
      await Promise.all([
        localforage
          .getItem("welcomeByState")
          .then((v) => {
            if (v.length) {
              this.welcomeByState = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(`error loading welcomeByState from localforage: ${e}`)
          ),
        localforage
          .getItem("surveyResponseByState")
          .then((v) => {
            if (v.length) {
              this.surveyResponseByState = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading surveyResponseByState from localforage: ${e}`
            )
          ),
        localforage
          .getItem("surveyResponseFemale")
          .then((v) => {
            if (v.length) {
              this.surveyResponseFemale = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading surveyResponseFemale from localforage: ${e}`
            )
          ),
      ]);
      console.log("after localforage promise");
      if (localData === 3) {
        console.log("retrieved all data");
        this.populatePlotData();
        this.isLoading = false;
      }
      console.log("before database promise");
      await Promise.all([
        this.getApiData(
          "/welcome_survey_award_response_by_state/",
          "welcomeByState",
          this.noFilter,
          this.stateSort
        ),
        this.getApiData(
          "/survey_response_by_state/",
          "surveyResponseByState",
          this.welcomeFilter,
          this.stateSort
        ),
        this.getApiData(
          "/survey_response_female/",
          "surveyResponseFemale",
          this.noFilter,
          this.noSort
        ),
      ]);
      console.log("after database promise");
      this.updateLocalData();
      this.populatePlotData();
      this.isLoading = false;
    }
  },
  methods: {
    populatePlotData: function () {
      // response counts
      this.responseCounts = [
        {
          values: [this.awardResponseCount, this.comparisonResponseCount],
          labels: ["Space Grant", "Comparison"],
          type: "pie",
          hole: 0.4,
          texttemplate: "%{value:,r}<br>%{percent}",
          marker: {
            colors: ["darkorange", "darkcyan"],
          },
        },
      ];
      this.responseLayout = {
        title: "All Pre-Survey Responses",
        xaxis: {
          tickformat: ",",
        },
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.totalResponseCount.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };

      // award response (% of award students completing)
      this.awardResponse = [
        {
          values: [
            this.awardResponseCount,
            this.awardTotalCount - this.awardResponseCount,
          ],
          labels: ["Responded", "No Response"],
          type: "pie",
          hole: 0.4,
          texttemplate: "%{value:,r}<br>%{percent}",
          marker: {
            colors: ["darkorange", "darkcyan"],
          },
        },
      ];
      this.awardResponseLayout = {
        title: "Space Grant Pre-Survey Responses",
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.awardTotalCount.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };

      // award pre by state all rounds
      let x = this.welcomeByStateAggregated.map((obj) => obj.pct);
      let text = this.welcomeByStateAggregated.map(
        (obj) => `${obj.responses}/${obj.students}`
      );
      let y = this.welcomeByStateAggregated.map((obj) => obj.state);

      this.allRoundStateHorizontalBar = [
        {
          x: x,
          y: y,
          text: text,
          type: "bar",
          orientation: "h",
          hovertemplate: "%{x} %<extra></extra> (%{text} responses)",
          showlegend: false,
        },
      ];

      this.allRoundStateLayout = {
        title: "Space Grant Student Cumulative Pre-Survey Response",
        xaxis: { title: "Percent of students responding" },
        height: 920,
      };

      // award pre by state round 2
      x = this.welcomeByState
        .filter((obj) => obj.survey_round === 3)
        .map((obj) => obj.pct);
      text = this.welcomeByState
        .filter((obj) => obj.survey_round === 3)
        .map((obj) => `${obj.responses}/${obj.students}`);
      y = this.welcomeByState
        .filter((obj) => obj.survey_round === 3)
        .map((obj) => obj.state);

      this.currentRoundStateHorizontalBar = [
        {
          x: x,
          y: y,
          text: text,
          type: "bar",
          orientation: "h",
          hovertemplate: "%{x} %<extra></extra> (%{text} responses)",
          showlegend: false,
        },
      ];

      this.currentRoundStateLayout = {
        title: "Space Grant Student Current Round Pre-Survey Response",
        xaxis: { title: "Percent of students responding" },
        height: 920,
      };

      // all by state
      // award
      x = this.surveyResponseByStateAggregated.map((obj) => obj.award_count);
      y = this.surveyResponseByStateAggregated.map((obj) => obj.state);

      const stateAwardBar = {
        x: x,
        y: y,
        type: "bar",
        name: "Space Grant",
        orientation: "h",
      };

      // comparison
      x = this.surveyResponseByStateAggregated.map(
        (obj) => obj.comparison_count
      );
      y = this.surveyResponseByStateAggregated.map((obj) => obj.state);

      const stateComparisonBar = {
        x: x,
        y: y,
        type: "bar",
        name: "Comparison",
        orientation: "h",
      };

      this.stateResponseBar = [stateAwardBar, stateComparisonBar];

      this.stateResponseLayout = {
        title: "Pre-Survey Responses",
        xaxis: { title: "Count of students responding" },
        height: 1200,
        barmode: "group",
        yaxis: {
          automargin: true,
          ticklen: 10,
        },
      };
    },
    updateLocalData: function () {
      localforage
        .setItem("welcomeByState", this.welcomeByState)
        .catch((e) =>
          console.log(`error setting welcomeByState to localforage: ${e}`)
        );
      localforage
        .setItem("surveyResponseFemale", this.surveyResponseFemale)
        .catch((e) =>
          console.log(`error setting surveyResponseFemale to localforage: ${e}`)
        );
      localforage
        .setItem("surveyResponseNonWhite", this.surveyResponseNonWhite)
        .catch((e) =>
          console.log(
            `error setting surveyResponseNonWhite to localforage: ${e}`
          )
        );
      localforage
        .setItem("surveyResponseByState", this.surveyResponseByState)
        .catch((e) =>
          console.log(
            `error setting surveyResponseByState to localforage: ${e}`
          )
        );
    },
    stateSort: function (a, b) {
      if (a.state > b.state) {
        return -1;
      }
      if (a.state < b.state) {
        return 1;
      }
      return 0;
    },
    noSort: function () {
      return 0;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    welcomeFilter: function (a) {
      return a.survey_stub === "welcome 2.0";
    },
    noFilter: function () {
      return 1;
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
  },
};
</script>

<style></style>
