<template>
  <v-container>
    <ChecklistNavDrawer />
    <!-- SNACK -->
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="2000"
      v-model="uploadSuccess"
    >
      <div class="text-body-1">
        uploaded to server ({{ rowsInserted }} rows)
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="uploadSuccess = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-container>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
      />
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="student"
        :items-per-page="25"
        :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
        :loading="!student || !student.length"
        dense
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th colspan="2">Student</th>
              <th colspan="3">Pre-Survey</th>
              <th colspan="2">Ongoing Survey</th>
              <th colspan="2">Post Survey</th>
              <th colspan="3">Interview</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
        </template>
        <template v-slot:[`item.student_date_added`]="{ item }">
          {{ formatDate(item.student_date_added) }}
        </template>
        <template v-slot:top>
          <v-dialog v-model="dialog" dense max-width="50%">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedStudent.student_name"
                        label="Name"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.student_date_added"
                        label="Email"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.pre_invite"
                        label="Pre-Survey Invite"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.pre_complete"
                        label="Pre-Survey Complete"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.pre_reward"
                        label="Pre-Survey Reward"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.ongoing_invite"
                        label="Ongoing Survey Invite"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.ongoing_reward"
                        label="Ongoing Survey Reward(s)"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.post_invite"
                        label="Post-Survey Invite"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.post_reward"
                        label="Post-Survey Reward"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.interview_invite"
                        label="Interview Invite"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.interview_schedule"
                        label="Interview Scheduled"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.interview_complete"
                        label="Interview Complete"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editStudent(item)"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import ChecklistNavDrawer from "../components/ChecklistNavDrawer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "ChecklistStudent",
  components: {
    Loading,
    ChecklistNavDrawer,
  },
  data: () => ({
    isLoading: false,
    uploadSuccess: false,
    rowsInserted: 0,
    checklistStudent: [],
    headers: [
      { text: "Name", value: "student_name" },
      { text: "Date", value: "student_date_added" },
      { text: "Invite", value: "pre_invite" },
      { text: "Complete", value: "pre_complete" },
      { text: "Reward", value: "pre_reward" },
      { text: "Invite", value: "ongoing_invite" },
      { text: "Reward", value: "ongoing_reward" },
      { text: "Invite", value: "post_invite" },
      { text: "Reward", value: "post_reward" },
      { text: "Invite", value: "interview_invite" },
      { text: "Schedule", value: "interview_schedule" },
      { text: "Complete", value: "interview_complete" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dialog: false,
    editedStudent: {
      student_name: "",
      student_date_added: "",
      pre_invite: "",
      pre_complete: "",
      pre_reward: "",
      ongoing_invite: "",
      ongoing_reward: "",
      post_invite: "",
      post_reward: "",
      interview_invite: "",
      interview_schedule: "",
      interview_complete: "",
    },
    defaultStudent: {
      student_name: "",
      student_date_added: "",
      pre_invite: "",
      pre_complete: "",
      pre_reward: "",
      ongoing_invite: "",
      ongoing_reward: "",
      post_invite: "",
      post_reward: "",
      interview_invite: "",
      interview_schedule: "",
      interview_complete: "",
    },
    editedIndex: -1,
  }),
  computed: {},
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      await Promise.all([
        this.getApiData(
          "/checklist_student",
          "checklistStudent",
          this.supersededFilter,
          this.nameSort
        ),
      ]);
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    sequenceSort: function (a, b) {
      return a.sequence - b.sequence;
    },
    nameSort: function (a, b) {
      if (a.student_name > b.student_name) {
        return 1;
      }
      if (a.student_name < b.student_name) {
        return -1;
      }
      return 0;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    editStudent: function (s) {
      this.editedIndex = this.checklistStudent.indexOf(s);
      this.editedStudent = Object.assign({}, s);
      this.dialog = true;
    },
    close: function () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedStudent = Object.assign({}, this.defaultStudent);
        this.editedIndex = -1;
      });
    },
    save: function () {
      if (this.editedIndex > -1) {
        Object.assign(
          this.checklistStudent[this.editedIndex],
          this.editedStudent
        );
      } else {
        this.checklistStudent.push(this.editedStudent);
      }
      // TODO: write editedStudent to database
      this.close();
    },
    upload: async function () {
      let student_payload = [];
      for (let i = 0; i < this.csvData.length; i++) {
        let row = this.csvData[i];
        let entry = {
          name: row.name && row.name.length ? row.name : null,
          email: row.email && row.email.length ? row.email : null,
          institution:
            row.institution && row.institution.length ? row.institution : null,
          institution_type:
            row.institutionType && row.institutionType.length
              ? row.institutionType.toLowerCase()
              : null,
          academic_level:
            row.academicLevel && row.academicLevel.length
              ? row.academicLevel.toLowerCase()
              : null,
          award_type:
            row.awardType && row.awardType.length
              ? row.awardType.toLowerCase()
              : null,
          award_amount:
            row.awardAmount && row.awardAmount.length
              ? parseInt(row.awardAmount)
              : null,
          date_added:
            row.dateAdded && row.dateAdded.length ? row.dateAdded : null,
        };
        student_payload.push(entry);
      }
      let mentor_payload = [];
      for (let i = 0; i < this.csvData.length; i++) {
        let row = this.csvData[i];
        let entry = {
          mentor_name:
            row.mentorName && row.mentorName.length ? row.mentorName : null,
          mentor_email:
            row.mentorEmail && row.mentorEmail.length ? row.mentorEmail : null,
        };
        mentor_payload.push(entry);
      }
      let student_mentor_payload = [];
      for (let i = 0; i < this.csvData.length; i++) {
        let row = this.csvData[i];
        let entry = {
          student_name: row.name && row.name.length ? row.name : null,
          student_date_added:
            row.dateAdded && row.dateAdded.length ? row.dateAdded : null,
          mentor_name:
            row.mentorName && row.mentorName.length ? row.mentorName : null,
          current_mentor: row.currentMentor ? row.currentMentor : true,
        };
        student_mentor_payload.push(entry);
      }
      let rowsInserted = 0;
      await Promise.all([
        axios
          .post("student/keys/u/", student_payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err)),
        axios
          .post("mentor/keys/u/", mentor_payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err)),
        axios
          .post("student_mentor/keys/u/", mentor_payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err)),
      ]);
      if (rowsInserted > 0) {
        this.rowsInserted = rowsInserted;
        this.uploadSuccess = true;
        this.fileName = null;
        this.csvData = [];
        this.csvErrors = [];
        this.header = [];
        this.rawData = null;
      }
    },
    // Assumes UTC because Postgres dates (no times) come in with
    // a "T00:00:00Z" timestamp, so they need to be interpreted as UTC
    formatDate: function (d) {
      let ts = new Date(d);
      return `${ts.getUTCFullYear()}-${(ts.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ts.getUTCDate().toString().padStart(2, "0")}`;
    },
    formatMentors: function (m) {
      let collapse = "";
      if (m && m.length) {
        m.forEach((mentor) => {
          if (mentor && mentor.length) {
            collapse = `${collapse}, ${mentor}`;
          }
        });
        collapse = collapse.replace(/^, /, "");
      }

      return collapse;
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
  },
};
</script>

<style scoped></style>
