<template>
  <ChecklistComparisonPreSurvey />
</template>

<script>
import ChecklistComparisonPreSurvey from "../components/ChecklistComparisonPreSurvey.vue";

export default {
  name: "ChecklistComparisonPreSurveyView",

  components: {
    ChecklistComparisonPreSurvey,
  },
};
</script>
