<template>
  <v-navigation-drawer app permanent clipped dark>
    <v-list>
      <v-list-item-content>
        <v-list-item-title class="text-h6 ml-2">{{ title }}</v-list-item-title>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
          link
        >
          <v-list-item-icon
            ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
          >
          <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
        </v-list-item>
      </v-list-item-content>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavDrawer",
  props: {},
  data: () => ({
    title: "Students & Mentors",
    items: [
      { title: "Student List", icon: "mdi-view-list", route: "student-list" },
      {
        title: "Mentor List",
        icon: "mdi-view-list",
        route: "mentor",
      },
      {
        title: "Name Crosswalk",
        icon: "mdi-account-arrow-right",
        route: "student-variant",
      },
      { title: "Upload", icon: "mdi-upload", route: "student-upload" },
    ],
  }),
};
</script>

<style></style>
