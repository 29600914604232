<template>
  <v-container>
    <StudentNavDrawer />
    <!-- SNACK -->
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="2000"
      v-model="uploadSuccess"
    >
      <div class="text-body-1">
        uploaded to server ({{ rowsInserted }} rows)
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="uploadSuccess = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-container>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
      />
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="mentor"
        :items-per-page="25"
        :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
        :loading="!mentor || !mentor.length"
        dense
      >
        <template v-slot:top>
          <v-dialog v-model="dialog" dense max-width="50%">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedMentor.mentor_name"
                        label="Name"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedMentor.mentor_email"
                        label="Email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" dense max-width="50%">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this mentor?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteConfirm"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editMentor(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small @click="deleteMentor(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import StudentNavDrawer from "../components/StudentNavDrawer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "StudentMentor",
  components: {
    Loading,
    StudentNavDrawer,
  },
  data: () => ({
    isLoading: false,
    uploadSuccess: false,
    rowsInserted: 0,
    mentor: [],
    headers: [
      { text: "Name", value: "mentor_name" },
      { text: "Email", value: "mentor_email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dialog: false,
    dialogDelete: false,
    editedMentor: {
      mentor_name: "",
      mentor_email: "",
    },
    defaultStudent: {
      mentor_name: "",
      mentor_email: "",
    },
    editedIndex: -1,
  }),
  computed: {},
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      await Promise.all([
        this.getApiData(
          "/mentor",
          "mentor",
          this.supersededFilter,
          this.sequenceSort
        ),
      ]);
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    sequenceSort: function (a, b) {
      return a.sequence - b.sequence;
    },
    nameSort: function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    editMentor: function (s) {
      this.editedIndex = this.mentor.indexOf(s);
      this.editedMentor = Object.assign({}, s);
      this.dialog = true;
    },
    deleteMentor: function (s) {
      this.editedIndex = this.mentor.indexOf(s);
      this.editedMentor = Object.assign({}, s);
      this.dialogDelete = true;
    },
    close: function () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedMentor = Object.assign({}, this.defaultMentor);
        this.editedIndex = -1;
      });
    },
    save: function () {
      if (this.editedIndex > -1) {
        Object.assign(this.mentor[this.editedIndex], this.editedMentor);
      } else {
        this.mentor.push(this.editedMentor);
      }
      // TODO: write editedStudent to database
      this.close();
    },
    closeDelete: function () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedMentor = Object.assign({}, this.defaultMentor);
        this.editedIndex = -1;
      });
    },
    deleteConfirm: function () {
      // TODO: delete editedStudent from database
      console.log(
        `TODO: delete mentor.serial == ${this.mentor[this.editedIndex].serial}`
      );
      this.mentor.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    upload: async function () {
      let mentor_payload = [];
      for (let i = 0; i < this.csvData.length; i++) {
        let row = this.csvData[i];
        let entry = {
          mentor_name:
            row.mentorName && row.mentorName.length ? row.mentorName : null,
          mentor_email:
            row.mentorEmail && row.mentorEmail.length ? row.mentorEmail : null,
        };
        mentor_payload.push(entry);
      }
      let rowsInserted = 0;
      await Promise.all([
        axios
          .post("mentor/keys/u/", mentor_payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err)),
      ]);
      if (rowsInserted > 0) {
        this.rowsInserted = rowsInserted;
        this.uploadSuccess = true;
        this.fileName = null;
        this.csvData = [];
        this.csvErrors = [];
        this.header = [];
        this.rawData = null;
      }
    },
    // Assumes UTC because Postgres dates (no times) come in with
    // a "T00:00:00Z" timestamp, so they need to be interpreted as UTC
    formatDate: function (d) {
      let ts = new Date(d);
      return `${ts.getUTCFullYear()}-${(ts.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ts.getUTCDate().toString().padStart(2, "0")}`;
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
  },
};
</script>

<style scoped></style>
