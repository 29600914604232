<template>
  <v-navigation-drawer app permanent clipped dark>
    <v-list>
      <v-list-item-content>
        <v-list-item-title class="text-h6 ml-2">{{ title }}</v-list-item-title>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
          link
        >
          <v-list-item-icon
            ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
          >
          <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
        </v-list-item>
      </v-list-item-content>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavDrawer",
  props: {},
  data: () => ({
    title: "Checklists",
    items: [
      {
        title: "Award Pre-Survey",
        icon: "mdi-account",
        route: "checklist-award-pre-survey",
      },
      {
        title: "Post Survey",
        icon: "mdi-account",
        route: "checklist-ongoing-survey",
      },
      {
        title: "Comparison Pre-Survey",
        icon: "mdi-account",
        route: "checklist-comparison-pre-survey",
      },
      {
        title: "Private Link Invite",
        icon: "mdi-account",
        route: "checklist-pre-link-invite",
      },
      // {
      //   title: "Student Checklist",
      //   icon: "mdi-account",
      //   route: "checklist-student",
      // },
      // {
      //   title: "Mentor Checklist",
      //   icon: "mdi-account-multiple",
      //   route: "checklist-mentor",
      // },
    ],
  }),
};
</script>

<style></style>
