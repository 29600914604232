import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import DashboardSurveyResponseView from "../views/DashboardSurveyResponseView.vue";
import DashboardScaleScoresView from "../views/DashboardScaleScoresView.vue";
import SurveyView from "../views/SurveyView.vue";
import SurveySingleView from "../views/SurveySingleView.vue";
import SurveyUnmatchedView from "../views/SurveyUnmatchedView.vue";
import StudentListView from "../views/StudentListView.vue";
import StudentVariantView from "../views/StudentVariantView.vue";
import StudentMentorView from "../views/StudentMentorView.vue";
import StudentUploadView from "../views/StudentUploadView.vue";
import ChecklistPreLinkInviteView from "../views/ChecklistPreLinkInviteView.vue";
import ChecklistAwardPreSurveyView from "../views/ChecklistAwardPreSurveyView.vue";
import ChecklistAwardOngoingSurveyView from "../views/ChecklistAwardOngoingSurveyView.vue";
import ChecklistOngoingSurveyView from "../views/ChecklistOngoingSurveyView.vue";
import ChecklistComparisonPreSurveyView from "../views/ChecklistComparisonPreSurveyView.vue";
import ChecklistStudentView from "../views/ChecklistStudentView.vue";
import ChecklistMentorView from "../views/ChecklistMentorView.vue";
import DownloadTablesView from "../views/DownloadTablesView.vue";
import HelpView from "../views/HelpView.vue";
import Login from "@/components/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/dashboard-survey-response",
    name: "dashboard-survey-response",
    component: DashboardSurveyResponseView,
  },
  {
    path: "/dashboard-scale-scores",
    name: "dashboard-scale-scores",
    component: DashboardScaleScoresView,
  },
  {
    path: "/single-survey",
    name: "single-survey",
    component: SurveyView,
  },
  {
    path: "/single-survey/:respondent_id",
    name: "single-survey-respondent",
    component: SurveySingleView,
  },
  {
    path: "/unmatched-survey",
    name: "unmatched-survey",
    component: SurveyUnmatchedView,
  },
  {
    path: "/student-list",
    name: "student-list",
    component: StudentListView,
  },
  {
    path: "/student-variant",
    name: "student-variant",
    component: StudentVariantView,
  },
  {
    path: "/mentor",
    name: "mentor",
    component: StudentMentorView,
  },
  {
    path: "/student-upload",
    name: "student-upload",
    component: StudentUploadView,
  },
  {
    path: "/checklist-pre-link-invite",
    name: "checklist-pre-link-invite",
    component: ChecklistPreLinkInviteView,
  },
  {
    path: "/checklist-award-pre-survey",
    name: "checklist-award-pre-survey",
    component: ChecklistAwardPreSurveyView,
  },
  {
    path: "/checklist-award-ongoing-survey",
    name: "checklist-award-ongoing-survey",
    component: ChecklistAwardOngoingSurveyView,
  },
  {
    path: "/checklist-ongoing-survey",
    name: "checklist-ongoing-survey",
    component: ChecklistOngoingSurveyView,
  },
  {
    path: "/checklist-comparison-pre-survey",
    name: "checklist-comparison-pre-survey",
    component: ChecklistComparisonPreSurveyView,
  },
  {
    path: "/checklist-student",
    name: "checklist-student",
    component: ChecklistStudentView,
  },
  {
    path: "/checklist-mentor",
    name: "checklist-mentor",
    component: ChecklistMentorView,
  },
  {
    path: "/download-tables",
    name: "download-tables",
    component: DownloadTablesView,
  },
  {
    path: "/help",
    name: "help",
    component: HelpView,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
