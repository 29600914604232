<template>
  <DashboardDemographics />
</template>

<script>
import DashboardDemographics from "../components/DashboardDemographics.vue";

export default {
  name: "DashboardDemographicsView",

  components: {
    DashboardDemographics,
  },
};
</script>
