<template>
  <ChecklistMentor />
</template>

<script>
import ChecklistMentor from "../components/ChecklistMentor.vue";

export default {
  name: "ChecklistMentorView",

  components: {
    ChecklistMentor,
  },
};
</script>
