<template>
  <ChecklistAwardPreSurvey />
</template>

<script>
import ChecklistAwardPreSurvey from "../components/ChecklistAwardPreSurvey.vue";

export default {
  name: "ChecklistAwardPreSurveyView",

  components: {
    ChecklistAwardPreSurvey,
  },
};
</script>
