<template>
  <ChecklistOngoingSurvey />
</template>

<script>
import ChecklistOngoingSurvey from "../components/ChecklistOngoingSurvey.vue";

export default {
  name: "ChecklistOngoingSurveyView",

  components: {
    ChecklistOngoingSurvey,
  },
};
</script>
