<template>
  <v-container>
    <StudentNavDrawer />
    <!-- SNACK -->
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="2000"
      v-model="uploadSuccess"
    >
      <div class="text-body-1">
        uploaded to server ({{ rowsInserted }} rows)
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="uploadSuccess = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-container>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
      />
    </v-container>
    <v-container>
      <v-row class="mb-2">
        <v-col>
          <v-switch
            v-model="includeOldSurveys"
            inset
            :label="`Include older surveys`"
            @change="changeOldSurvey"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col>
          <v-text-field
            v-model="tableSearch"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            clearable
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="student"
        :items-per-page="25"
        :search="tableSearch"
        :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
        :loading="!student || !student.length"
        dense
      >
        <template v-slot:[`item.date_added`]="{ item }">
          {{ formatDate(item.date_added) }}
        </template>
        <template v-slot:[`item.mentors`]="{ item }">
          {{ formatMentors(item.mentors) }}
        </template>
        <template v-slot:top>
          <v-dialog v-model="dialog" dense max-width="50%">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedStudent.name"
                        label="Name"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.email"
                        label="Email"
                      ></v-text-field>
                      <v-select
                        v-model="editedStudent.state"
                        label="State"
                        :items="refState"
                        item-text="title"
                        item-value="code"
                      ></v-select>
                      <v-select
                        v-model="editedStudent.institution_type"
                        :items="refSchoolLevel"
                        label="Institution Type"
                        item-text="title"
                        item-value="code"
                      ></v-select>
                      <!-- <v-select -->
                      <!--   v-model="editedStudent.award_type" -->
                      <!--   :items="refAwardType" -->
                      <!--   label="Award Type" -->
                      <!--   item-text="title" -->
                      <!--   item-value="code" -->
                      <!-- ></v-select> -->
                      <v-text-field
                        v-model="editedStudent.award_type"
                        label="Award Type"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.award_amount"
                        label="Award Amount"
                      ></v-text-field>
                      <v-select
                        v-model="editedStudent.award_start"
                        :items="refAwardStart"
                        label="Award Start"
                        item-text="title"
                        item-value="code"
                      ></v-select>
                      <MentorArraySelect
                        :mentors="editedStudent.mentors"
                        :mentor="mentor"
                        @update="console.log($event)"
                      ></MentorArraySelect>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" dense max-width="50%">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this student?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteConfirm"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editStudent(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small @click="deleteStudent(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import StudentNavDrawer from "../components/StudentNavDrawer.vue";
import MentorArraySelect from "../components/MentorArraySelect.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "StudentList",
  components: {
    Loading,
    StudentNavDrawer,
    MentorArraySelect,
  },
  data: () => ({
    isLoading: false,
    uploadSuccess: false,
    rowsInserted: 0,
    includeOldSurveys: false,
    student: [],
    studentMentor: [],
    mentor: [],
    tableSearch: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "State", value: "state" },
      { text: "Institution Type", value: "institution_type" },
      { text: "Award Type", value: "award_type" },
      { text: "Award Amount", value: "award_amount" },
      { text: "Award Start", value: "award_start" },
      { text: "Mentors", value: "mentors" },
      { text: "Date Added", value: "date_added", filterable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dialog: false,
    dialogDelete: false,
    editedStudent: {
      serial: null,
      name: "",
      email: "",
      state: "",
      institution: "",
      institution_type: "",
      award_amount: "",
      award_start: "",
      award_type: "",
      mentors: [],
      status: "",
      notes: "",
      date_added: "",
    },
    defaultStudent: {
      serial: null,
      name: "",
      email: "",
      state: "",
      institution: "",
      institution_type: "",
      award_amount: "",
      award_start: "",
      award_type: "",
      mentors: [],
      status: "",
      notes: "",
      date_added: "",
    },
    editedIndex: -1,
    refAwardStart: [],
    refSchoolLevel: [],
    refSchoolYear: [],
    refState: [],
  }),
  computed: {},
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      await Promise.all([
        this.getApiData(
          "/student",
          "student",
          this.studentDateAddedFilter,
          this.nameSort
        ),
        this.getApiData(
          "/student_mentor",
          "studentMentor",
          this.studentMentorDateAddedFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/mentor",
          "mentor",
          this.supersededFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/ref_institution_type",
          "refSchoolLevel",
          this.supersededFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/ref_school_year",
          "refSchoolYear",
          this.supersededFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/ref_award_start",
          "refAwardStart",
          this.supersededFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/ref_state",
          "refState",
          this.supersededFilter,
          this.sequenceSort
        ),
      ]);
      this.student.forEach((s) => {
        s.mentors = [];
        this.studentMentor.forEach((m) => {
          if (
            s.name === m.student_name &&
            s.date_added === m.student_date_added
          ) {
            s.mentors.push(m.mentor_name);
          }
        });
      });
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    sequenceSort: function (a, b) {
      return a.sequence - b.sequence;
    },
    nameSort: function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    studentDateAddedFilter: function (a) {
      const dateAdded = new Date(a.date_added);
      const studentStatus = a.status;
      const newDate = new Date("2023-01-01");

      if (
        this.includeOldSurveys ||
        dateAdded > newDate ||
        studentStatus === "award"
      ) {
        return true;
      } else {
        return false;
      }
    },
    studentMentorDateAddedFilter: function (a) {
      const dateAdded = new Date(a.student_date_added);
      const newDate = new Date("2023-01-01");

      if (this.includeOldSurveys || dateAdded > newDate) {
        return true;
      } else {
        return false;
      }
    },
    editStudent: function (s) {
      this.editedIndex = this.student.indexOf(s);
      this.editedStudent = Object.assign({}, s);
      this.dialog = true;
    },
    deleteStudent: function (s) {
      this.editedIndex = this.student.indexOf(s);
      this.editedStudent = Object.assign({}, s);
      this.dialogDelete = true;
    },
    close: function () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedStudent = Object.assign({}, this.defaultStudent);
        this.editedIndex = -1;
      });
    },
    save: async function () {
      if (this.editedIndex > -1) {
        Object.assign(this.student[this.editedIndex], this.editedStudent);
      } else {
        this.student.push(this.editedStudent);
      }
      let student_payload = [];
      let entry = {
        serial: this.editedStudent.serial,
        name: this.editedStudent.name,
        email:
          this.editedStudent.email && this.editedStudent.email.length
            ? this.editedStudent.email
            : null,
        institution:
          this.editedStudent.institution &&
          this.editedStudent.institution.length
            ? this.editedStudent.institution
            : null,
        institution_type:
          this.editedStudent.institution_type &&
          this.editedStudent.institution_type.length
            ? this.editedStudent.institution_type
            : null,
        academic_level:
          this.editedStudent.academic_level &&
          this.editedStudent.academic_level.length
            ? this.editedStudent.academic_level
            : null,
        award_type:
          this.editedStudent.award_type && this.editedStudent.award_type.length
            ? this.editedStudent.award_type
            : null,
        award_amount:
          this.editedStudent.award_amount &&
          this.editedStudent.award_amount.length
            ? this.editedStudent.award_amount
            : null,
        award_start:
          this.editedStudent.award_start &&
          this.editedStudent.award_start.length
            ? this.editedStudent.award_start
            : null,
        state:
          this.editedStudent.state && this.editedStudent.state.length
            ? this.editedStudent.state
            : null,
        status:
          this.editedStudent.status && this.editedStudent.status.length
            ? this.editedStudent.status
            : null,
        notes:
          this.editedStudent.notes && this.editedStudent.notes.length
            ? this.editedStudent.notes
            : null,
        date_added: this.editedStudent.date_added,
      };
      student_payload.push(entry);
      console.log(student_payload);
      let student_mentor_payload = [];
      for (let i = 0; i < this.editedStudent.mentors.length; i++) {
        let entry = {
          student_name: this.editedStudent.name,
          student_date_added: this.editedStudent.date_added,
          mentor_name:
            this.editedStudent.mentors[i] &&
            this.editedStudent.mentors[i].length
              ? this.editedStudent.mentors[i]
              : null,
        };
        student_mentor_payload.push(entry);
      }
      console.log(student_mentor_payload);
      let rowsInserted = 0;
      let updates = [
        axios
          .post("student/keys/p/", student_payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err)),
      ];
      if (student_mentor_payload.length > 0) {
        updates.push(
          axios
            .post("student_mentor/keys/u/", student_mentor_payload, {
              headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
              if (response.status === 200) {
                for (let i in response.data.message) {
                  if (
                    response.data.status[i] != "error" &&
                    response.data.message[i].search("inserted") > -1
                  ) {
                    rowsInserted = rowsInserted + 1;
                  } else {
                    console.log(
                      "error submitting: " + response.data.message[i]
                    );
                  }
                }
              } else {
                console.log("post fail, code = " + response.status);
              }
            })
            .catch((err) => console.log("error posting: ", err))
        );
      }
      await Promise.all(updates);

      this.close();
    },
    closeDelete: function () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedStudent = Object.assign({}, this.defaultStudent);
        this.editedIndex = -1;
      });
    },
    deleteConfirm: function () {
      // TODO: delete editedStudent from database
      console.log(
        `TODO: delete student.serial == ${
          this.student[this.editedIndex].serial
        }`
      );
      this.student.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    upload: async function () {
      let student_payload = [];
      for (let i = 0; i < this.csvData.length; i++) {
        let row = this.csvData[i];
        let entry = {
          name: row.name && row.name.length ? row.name : null,
          email: row.email && row.email.length ? row.email : null,
          institution:
            row.institution && row.institution.length ? row.institution : null,
          institution_type:
            row.institutionType && row.institutionType.length
              ? row.institutionType.toLowerCase()
              : null,
          academic_level:
            row.academicLevel && row.academicLevel.length
              ? row.academicLevel.toLowerCase()
              : null,
          award_type:
            row.awardType && row.awardType.length
              ? row.awardType.toLowerCase()
              : null,
          award_amount:
            row.awardAmount && row.awardAmount.length
              ? parseInt(row.awardAmount)
              : null,
          award_start:
            row.awardStart && row.awardStart.length
              ? parseInt(row.awardStart)
              : null,
          date_added:
            row.dateAdded && row.dateAdded.length ? row.dateAdded : null,
        };
        student_payload.push(entry);
      }
      let mentor_payload = [];
      for (let i = 0; i < this.csvData.length; i++) {
        let row = this.csvData[i];
        let entry = {
          mentor_name:
            row.mentorName && row.mentorName.length ? row.mentorName : null,
          mentor_email:
            row.mentorEmail && row.mentorEmail.length ? row.mentorEmail : null,
        };
        mentor_payload.push(entry);
      }
      let student_mentor_payload = [];
      for (let i = 0; i < this.csvData.length; i++) {
        let row = this.csvData[i];
        let entry = {
          student_name: row.name && row.name.length ? row.name : null,
          student_date_added:
            row.dateAdded && row.dateAdded.length ? row.dateAdded : null,
          mentor_name:
            row.mentorName && row.mentorName.length ? row.mentorName : null,
          current_mentor: row.currentMentor ? row.currentMentor : true,
        };
        student_mentor_payload.push(entry);
      }
      let rowsInserted = 0;
      await Promise.all([
        axios
          .post("student/keys/u/", student_payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err)),
        axios
          .post("mentor/keys/u/", mentor_payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err)),
        axios
          .post("student_mentor/keys/u/", mentor_payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err)),
      ]);
      if (rowsInserted > 0) {
        this.rowsInserted = rowsInserted;
        this.uploadSuccess = true;
        this.fileName = null;
        this.csvData = [];
        this.csvErrors = [];
        this.header = [];
        this.rawData = null;
      }
    },
    changeOldSurvey: async function () {
      this.isLoading = true;
      this.student = [];
      this.studentMentor = [];
      this.mentor = [];
      await Promise.all([
        this.getApiData(
          "/student",
          "student",
          this.studentDateAddedFilter,
          this.nameSort
        ),
        this.getApiData(
          "/student_mentor",
          "studentMentor",
          this.studentMentorDateAddedFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/mentor",
          "mentor",
          this.supersededFilter,
          this.sequenceSort
        ),
      ]);
      this.student.forEach((s) => {
        s.mentors = [];
        this.studentMentor.forEach((m) => {
          if (
            s.name === m.student_name &&
            s.date_added === m.student_date_added
          ) {
            s.mentors.push(m.mentor_name);
          }
        });
      });
      this.isLoading = false;
    },
    // Assumes UTC because Postgres dates (no times) come in with
    // a "T00:00:00Z" timestamp, so they need to be interpreted as UTC
    formatDate: function (d) {
      if (d && d.length) {
        let ts = new Date(d);
        return `${ts.getUTCFullYear()}-${(ts.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}-${ts.getUTCDate().toString().padStart(2, "0")}`;
      } else {
        return null;
      }
    },
    formatMentors: function (m) {
      let collapse = "";
      if (m && m.length) {
        m.forEach((mentor) => {
          if (mentor && mentor.length) {
            collapse = `${collapse}, ${mentor}`;
          }
        });
        collapse = collapse.replace(/^, /, "");
      }

      return collapse;
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
  },
};
</script>

<style scoped></style>
