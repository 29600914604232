<template>
  <v-container>
    <DashboardNavDrawer />
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true" />
    <v-container
      v-if="
        $store.getters.username && $store.getters.username.length && !isLoading
      "
    >
      <v-row>
        <h2>All Students</h2>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="5">
          <Plotly
            :data="femaleCounts"
            :layout="femaleLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
        <v-col cols="5">
          <Plotly
            :data="nonWhiteCounts"
            :layout="nonWhiteLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
      </v-row>
      <v-row> <h2>Space Grant Students</h2> </v-row
      ><v-row>
        <v-col cols="1"></v-col>
        <v-col cols="5">
          <Plotly
            :data="femaleAwardCounts"
            :layout="femaleAwardLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
        <v-col cols="5">
          <Plotly
            :data="nonWhiteAwardCounts"
            :layout="nonWhiteAwardLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
      </v-row>
      <v-row> <h2>Comparison Students</h2> </v-row
      ><v-row>
        <v-col cols="1"></v-col>
        <v-col cols="5">
          <Plotly
            :data="femaleComparisonCounts"
            :layout="femaleComparisonLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
        <v-col cols="5">
          <Plotly
            :data="nonWhiteComparisonCounts"
            :layout="nonWhiteComparisonLayout"
            :display-mode-bar="false"
          >
          </Plotly>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import DashboardNavDrawer from "../components/DashboardNavDrawer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";
import { Plotly } from "vue-plotly";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "DashboardDemographics",
  components: {
    DashboardNavDrawer,
    Loading,
    Plotly,
  },
  data: () => ({
    isLoading: false,
    surveyResponseFemale: [],
    surveyResponseNonWhite: [],
    stateHorizontalBar: null,
    stateLayout: null,
    stateResponseBar: null,
    stateResponseLayout: null,
    responseCounts: null,
    responseLayout: null,
    femaleCounts: null,
    femaleLayout: null,
    femaleAwardCounts: null,
    femaleAwardLayout: null,
    femaleComparisonCounts: null,
    femaleComparisonLayout: null,
    nonWhiteCounts: null,
    nonWhiteLayout: null,
    nonWhiteAwardCounts: null,
    nonWhiteAwardLayout: null,
    nonWhiteComparisonCounts: null,
    nonWhiteComparisonLayout: null,
  }),
  computed: {
    awardResponseCount: function () {
      return this.surveyResponseFemale.filter(
        (e) => e.student_status === "award"
      )[0].total;
    },
    comparisonResponseCount: function () {
      return this.surveyResponseFemale.filter(
        (e) => e.student_status === "comparison"
      )[0].total;
    },
    totalResponseCount: function () {
      return this.surveyResponseFemale.filter(
        (e) => e.student_status === "total"
      )[0].total;
    },
  },
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      this.isLoading = true;
      let localData = 0;
      console.log("before localforage promise");
      await Promise.all([
        localforage
          .getItem("surveyResponseFemale")
          .then((v) => {
            if (v.length) {
              this.surveyResponseFemale = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading surveyResponseFemale from localforage: ${e}`
            )
          ),
        localforage
          .getItem("surveyResponseNonWhite")
          .then((v) => {
            if (v.length) {
              this.surveyResponseNonWhite = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading surveyResponseNonWhite from localforage: ${e}`
            )
          ),
      ]);
      console.log("after localforage promise");
      if (localData === 3) {
        console.log("retrieved all data");
        this.populatePlotData();
        this.isLoading = false;
      }
      console.log("before database promise");
      await Promise.all([
        this.getApiData(
          "/survey_response_female/",
          "surveyResponseFemale",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/survey_response_non_white/",
          "surveyResponseNonWhite",
          this.supersededFilter,
          this.stateSort
        ),
      ]);
      console.log("after database promise");
      this.updateLocalData();
      this.populatePlotData();
      this.isLoading = false;
    }
  },
  methods: {
    populatePlotData: function () {
      // response counts
      this.responseCounts = [
        {
          values: [this.awardResponseCount, this.comparisonResponseCount],
          labels: ["Space Grant", "Comparison"],
          type: "pie",
          hole: 0.4,
          texttemplate: "%{value:,r}<br>%{percent}",
          marker: {
            colors: ["darkorange", "darkcyan"],
          },
        },
      ];
      this.responseLayout = {
        title: "All Pre-Survey Responses",
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0,
          pad: 0,
        },
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.totalResponseCount.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };

      // response counts
      this.responseCounts = [
        {
          values: [this.awardResponseCount, this.comparisonResponseCount],
          labels: ["Space Grant", "Comparison"],
          type: "pie",
          hole: 0.4,
          texttemplate: "%{value:,r}<br>%{percent}",
        },
      ];
      this.responseLayout = {
        title: "Pre-Survey Responses",
        margin: {
          l: 0,
          r: 0,
        },
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.totalResponseCount.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };

      // overall female counts
      this.femaleCounts = [
        {
          values: [
            this.surveyResponseFemale.filter(
              (e) => e.student_status === "total"
            )[0].female,
            this.surveyResponseFemale.filter(
              (e) => e.student_status === "total"
            )[0].total -
              this.surveyResponseFemale.filter(
                (e) => e.student_status === "total"
              )[0].female,
          ],
          labels: ["Yes", "No"],
          type: "pie",
          hole: 0.4,
          texttemplate: "%{value:,r}<br>%{percent}",
          marker: {
            colors: ["darkorange", "darkcyan"],
          },
        },
      ];
      this.femaleLayout = {
        title: "Identify as Female",
        margin: {
          l: 0,
          r: 0,
        },
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.surveyResponseFemale
              .filter((e) => e.student_status === "total")[0]
              .total.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };
      // overall non-white counts
      this.nonWhiteCounts = [
        {
          values: [
            this.surveyResponseNonWhite.filter(
              (e) => e.student_status === "total"
            )[0].non_white,
            this.surveyResponseNonWhite.filter(
              (e) => e.student_status === "total"
            )[0].total -
              this.surveyResponseNonWhite.filter(
                (e) => e.student_status === "total"
              )[0].non_white,
          ],
          labels: ["Yes", "No"],
          type: "pie",
          hole: 0.4,
          marker: {
            colors: ["darkorange", "darkcyan"],
          },
          texttemplate: "%{value:,r}<br>%{percent}",
        },
      ];
      this.nonWhiteLayout = {
        title: "Non-white, Non-Asian",
        margin: {
          l: 0,
          r: 0,
        },
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.surveyResponseNonWhite
              .filter((e) => e.student_status === "total")[0]
              .total.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };
      // award female counts
      this.femaleAwardCounts = [
        {
          values: [
            this.surveyResponseFemale.filter(
              (e) => e.student_status === "award"
            )[0].female,
            this.surveyResponseFemale.filter(
              (e) => e.student_status === "award"
            )[0].total -
              this.surveyResponseFemale.filter(
                (e) => e.student_status === "award"
              )[0].female,
          ],
          labels: ["Yes", "No"],
          type: "pie",
          hole: 0.4,
          texttemplate: "%{value:,r}<br>%{percent}",
          marker: {
            colors: ["darkorange", "darkcyan"],
          },
        },
      ];
      this.femaleAwardLayout = {
        title: "Identify as Female",
        margin: {
          l: 0,
          r: 0,
        },
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.surveyResponseFemale
              .filter((e) => e.student_status === "award")[0]
              .total.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };
      // overall non-white counts
      this.nonWhiteAwardCounts = [
        {
          values: [
            this.surveyResponseNonWhite.filter(
              (e) => e.student_status === "award"
            )[0].non_white,
            this.surveyResponseNonWhite.filter(
              (e) => e.student_status === "award"
            )[0].total -
              this.surveyResponseNonWhite.filter(
                (e) => e.student_status === "award"
              )[0].non_white,
          ],
          labels: ["Yes", "No"],
          type: "pie",
          hole: 0.4,
          texttemplate: "%{value:,r}<br>%{percent}",
          marker: {
            colors: ["darkorange", "darkcyan"],
          },
        },
      ];
      this.nonWhiteAwardLayout = {
        title: "Non-white, Non-Asian",
        margin: {
          l: 0,
          r: 0,
        },
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.surveyResponseNonWhite
              .filter((e) => e.student_status === "award")[0]
              .total.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };
      // comparison female counts
      this.femaleComparisonCounts = [
        {
          values: [
            this.surveyResponseFemale.filter(
              (e) => e.student_status === "comparison"
            )[0].female,
            this.surveyResponseFemale.filter(
              (e) => e.student_status === "comparison"
            )[0].total -
              this.surveyResponseFemale.filter(
                (e) => e.student_status === "comparison"
              )[0].female,
          ],
          labels: ["Yes", "No"],
          type: "pie",
          hole: 0.4,
          texttemplate: "%{value:,r}<br>%{percent}",
          marker: {
            colors: ["darkorange", "darkcyan"],
          },
        },
      ];
      this.femaleComparisonLayout = {
        title: "Identify as Female",
        margin: {
          l: 0,
          r: 0,
        },
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.surveyResponseFemale
              .filter((e) => e.student_status === "comparison")[0]
              .total.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };
      // overall non-white counts
      this.nonWhiteComparisonCounts = [
        {
          values: [
            this.surveyResponseNonWhite.filter(
              (e) => e.student_status === "comparison"
            )[0].non_white,
            this.surveyResponseNonWhite.filter(
              (e) => e.student_status === "comparison"
            )[0].total -
              this.surveyResponseNonWhite.filter(
                (e) => e.student_status === "comparison"
              )[0].non_white,
          ],
          labels: ["Yes", "No"],
          type: "pie",
          hole: 0.4,
          texttemplate: "%{value:,r}<br>%{percent}",
          marker: {
            colors: ["darkorange", "darkcyan"],
          },
        },
      ];
      this.nonWhiteComparisonLayout = {
        title: "Non-white, Non-Asian",
        margin: {
          l: 0,
          r: 0,
        },
        annotations: [
          {
            font: {
              size: 20,
            },
            text: this.surveyResponseNonWhite
              .filter((e) => e.student_status === "comparison")[0]
              .total.toLocaleString(),
            x: 0.5,
            y: 0.5,
            showarrow: false,
          },
        ],
      };
    },
    updateLocalData: function () {
      localforage
        .setItem("surveyResponseFemale", this.surveyResponseFemale)
        .catch((e) =>
          console.log(`error setting surveyResponseFemale to localforage: ${e}`)
        );
      localforage
        .setItem("surveyResponseNonWhite", this.surveyResponseNonWhite)
        .catch((e) =>
          console.log(
            `error setting surveyResponseNonWhite to localforage: ${e}`
          )
        );
    },
    stateSort: function (a, b) {
      if (a.state > b.state) {
        return -1;
      }
      if (a.state < b.state) {
        return 1;
      }
      return 0;
    },
    noSort: function () {
      return 0;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    noFilter: function () {
      return 1;
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
  },
};
</script>

<style></style>
