<template>
  <ChecklistPreLinkInvite />
</template>

<script>
import ChecklistPreLinkInvite from "../components/ChecklistPreLinkInvite.vue";

export default {
  name: "ChecklistPreLinkInviteView",

  components: {
    ChecklistPreLinkInvite,
  },
};
</script>
