<template>
  <v-container>
    <StudentNavDrawer />
    <!-- SNACK -->
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="2000"
      v-model="uploadSuccess"
    >
      <div class="text-body-1">
        uploaded to server ({{ rowsInserted }} rows)
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="uploadSuccess = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-container>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
      />
    </v-container>
    <v-container>
      <v-row class="mb-2">
        <v-col cols="10">
          <p>
            Upload student lists by clicking on the "Email List" box below, and
            choosing a CSV file. The CSV file should have the following columns,
            in this order. Column names do not need to match exactly, but should
            be similar.
          </p>
          <p class="ml-4">
            <span class="code">Name</span>
            <span class="code">Email Address</span>
            <span class="code">School</span>
            <span class="code">Level</span>
            <span class="code">Type of Award</span>
            <span class="code">Award Start</span>
            <span class="code">Award Amount</span>
          </p>
          <p>
            Verify the data was read correctly, then press the "Upload" button
            to add to the server database.
          </p>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="10">
          <v-file-input
            label="Email List (CSV)"
            filled
            truncate-length="250"
            accept=".csv"
            show-size
            prepend-icon="mdi-table-arrow-up"
            v-model="fileName"
            @change="readFile"
          />
        </v-col>
      </v-row>
      <v-row v-if="csvErrors.length > 0">
        <v-col>
          <p>Errors:</p>
          <p></p>
          <ul>
            <li v-for="err in csvErrors" v-bind:key="err">{{ err }}</li>
          </ul>
        </v-col>
        <v-col cols="2"> </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-data-table :headers="headers" :items="csvData" />
        </v-col>
        <v-col
          v-if="validHeader == true && csvData.length > 0"
          align-self="end"
          cols="2"
          ><v-btn color="primary" @click="upload">Upload</v-btn></v-col
        >
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import StudentNavDrawer from "../components/StudentNavDrawer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "UploadStudents",
  components: {
    Loading,
    StudentNavDrawer,
  },
  data: () => ({
    isLoading: false,
    fileName: null,
    rawData: null,
    header: null,
    csvData: [],
    validHeader: false,
    csvErrors: [],
    uploadSuccess: false,
    rowsInserted: 0,
    requiredHeader: [
      "name",
      "(e-?mail|address)",
      "(institution|school|university)",
      "(grad|level)",
      "award",
      "start",
      "amount",
    ],
    headers: [
      { text: "Name", value: "name" },
      { text: "Email Address", value: "email" },
      { text: "School", value: "school" },
      { text: "Level", value: "schoolLevel" },
      { text: "Type of Award", value: "awardType" },
      { text: "Award Start", value: "awardStart" },
      { text: "Award Amount", value: "awardAmount" },
      { text: "Date Added", value: "dateAdded" },
    ],
    refAwardType: [],
    refSchoolLevel: [],
    refSchoolYear: [],
  }),
  computed: {},
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    this.isLoading = true;
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      await Promise.all([
        this.getApiData(
          "/ref_award_type",
          "refAwardType",
          this.supersededFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/ref_school_level",
          "refSchoolLevel",
          this.supersededFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/ref_school_year",
          "refSchoolYear",
          this.supersededFilter,
          this.sequenceSort
        ),
      ]);
    }
    this.isLoading = false;
  },
  methods: {
    sequenceSort: function (a, b) {
      return a.sequence - b.sequence;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    readFile: function () {
      if (!this.fileName) {
        this.rawData = "No file chosen";
      } else {
        let reader = new FileReader();
        reader.readAsText(this.fileName);
        reader.onload = () => {
          this.rawData = reader.result;
          let parsedCSV = this.$papa.parse(this.rawData, {
            skipEmptyLines: true,
          }).data;
          this.header = parsedCSV.shift();
          this.validateHeader();
          this.rowsToObjects(parsedCSV);
          this.validateReferenceColumns();
        };
      }
    },
    rowsToObjects: function (parsedCSV) {
      this.csvData = [];
      for (let i = 0; i < parsedCSV.length; i++) {
        let row = {};
        for (let j = 0; j < this.headers.length; j++) {
          row[this.headers[j].value] = parsedCSV[i][j];
        }
        this.csvData.push(row);
      }
    },
    validateHeader: function () {
      let valid = true;
      this.csvErrors = [];
      if (this.header.length < this.requiredHeader.length) {
        valid = false;
      } else {
        for (let i = 0; i < this.requiredHeader.length; i++) {
          let re = new RegExp(this.requiredHeader[i], "i");
          if (this.header[i].search(re) < 0) {
            this.csvErrors.push(
              `Error matching column ${i + 1} (${this.header[i]}) using /${
                this.requiredHeader[i]
              }/`
            );
            valid = false;
          }
        }
      }
      this.validHeader = valid;
    },
    validateReferenceColumns: function () {
      this.csvData.forEach((student, i) => {
        if (
          student.schoolLevel &&
          !this.refSchoolLevel
            .map((e) => e.code)
            .includes(student.schoolLevel.toLowerCase())
        ) {
          this.csvErrors.push(
            `School Level in row ${i + 1} (${
              student.schoolLevel
            }) not in ref_school_level (${this.refSchoolLevel.map(
              (e) => e.code
            )})`
          );
        }
        if (
          student.awardType &&
          !this.refAwardType
            .map((e) => e.code)
            .includes(student.awardType.toLowerCase())
        ) {
          this.csvErrors.push(
            `Award Type in row ${i + 1} (${
              student.awardType
            }) not in ref_award_type (${this.refAwardType.map((e) => e.code)})`
          );
        }
        if (!student.name || student.name.length === 0) {
          this.csvErrors.push(`Student name is missing from row ${i + 1}`);
        }
      });
    },
    upload: async function () {
      let student_payload = [];
      for (let i = 0; i < this.csvData.length; i++) {
        let row = this.csvData[i];
        let entry = {
          name: row.name && row.name.length ? row.name : null,
          email: row.email && row.email.length ? row.email : null,
          school: row.school && row.school.length ? row.school : null,
          school_level:
            row.schoolLevel && row.schoolLevel.length
              ? row.schoolLevel.toLowerCase()
              : null,
          award_type:
            row.awardType && row.awardType.length
              ? row.awardType.toLowerCase()
              : null,
          award_start:
            row.awardStart && row.awardStart.length ? row.awardStart : null,
          award_amount:
            row.awardAmount && row.awardAmount.length
              ? parseInt(row.awardAmount)
              : null,
          date_added: this.todayDate(),
        };
        student_payload.push(entry);
      }
      let rowsInserted = 0;
      await Promise.all([
        axios
          .post("student/keys/u/", student_payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err)),
      ]);
      if (rowsInserted > 0) {
        this.rowsInserted = rowsInserted;
        this.uploadSuccess = true;
        this.fileName = null;
        this.csvData = [];
        this.csvErrors = [];
        this.header = [];
        this.rawData = null;
      }
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
    todayDate: function () {
      let d = new Date();
      return `${d.getFullYear()}-${(d.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
.code {
  white-space: nowrap;
  padding: 5px;
  margin-right: 5px;
  background-color: rgba(1, 1, 0, 0.1);
  border: 1px solid rgba(1, 1, 1, 0.25);
}
</style>
