<template>
  <v-navigation-drawer app permanent clipped dark>
    <v-list>
      <v-list-item-content>
        <v-list-item-title class="text-h6 ml-2">{{ title }}</v-list-item-title>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.route"
          link
        >
          <v-list-item-icon
            ><v-icon>{{ item.icon }}</v-icon></v-list-item-icon
          >
          <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
        </v-list-item>
      </v-list-item-content>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "NavDrawer",
  props: {},
  data: () => ({
    title: "Dashboard",
    items: [
      {
        title: "Demographics",
        icon: "mdi-chart-arc",
        // route: "dashboard-demographics",
        route: "/",
      },
      {
        title: "Survey Response",
        icon: "mdi-chart-bar",
        route: "dashboard-survey-response",
      },
      {
        title: "Scale Scores",
        icon: "mdi-account",
        route: "dashboard-scale-scores",
      },
    ],
  }),
  computed: {
    currentRoute() {
      return this.$route.name;
    },
  },
};
</script>

<style></style>
