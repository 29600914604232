<template>
  <div>
    <v-tooltip v-if="check && check.length" top>
      <template v-slot:activator="{ on }">
        <v-btn icon small @click="clicked" v-on="on"
          ><v-icon>mdi-check-circle</v-icon></v-btn
        >
      </template>
      <span>{{ check }}</span>
    </v-tooltip>
    <v-btn icon v-if="!check || check.length === 0" small @click="clicked"
      ><v-icon>mdi-circle-outline</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  name: "CustomCheck",
  props: {
    check: String,
  },
  computed: {},
  methods: {
    clicked: function () {
      if (this.check && this.check.length) {
        this.$emit("check-clicked", { new: null, old: this.check });
      } else {
        const ts = new Date();
        const dstr = `${ts.getUTCFullYear()}-${(ts.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}-${ts.getUTCDate().toString().padStart(2, "0")}`;
        this.$emit("check-clicked", { new: dstr, old: null });
      }
    },
  },
};
</script>

<style></style>
