<template>
  <v-container>
    <DashboardNavDrawer />
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true" />
    <v-container
      v-if="
        $store.getters.username && $store.getters.username.length && !isLoading
      "
    >
      <v-container class="fixed">
        <v-row class="m-0 p-0">
          <v-col cols="1">Filters:</v-col>
          <v-col cols="4">
            <v-select
              class="mb-2"
              label="Region"
              v-model="regionFilter"
              :items="refStateRegion"
              item-text="region_title"
              item-value="region_code"
              outlined
              dense
              clearable
              hide-details
              @change="
                stateFilter = null;
                populatePlotData();
              "
            ></v-select>
          </v-col>
          <v-col cols="4"
            ><v-select
              class="mb-2"
              label="State"
              v-model="stateFilter"
              :items="refState"
              item-text="title"
              item-value="title"
              outlined
              dense
              clearable
              hide-details
              @change="
                regionFilter = null;
                populatePlotData();
              "
            ></v-select>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-container>
      <v-container class="post-fixed">
        <v-row>
          <h2>
            Total Survey Responses:
            {{ scoringResponseCountFiltered.totalCount.toLocaleString() }}
          </h2>
        </v-row>
        <v-row>
          <h3>
            Space Grant / Comparison:
            {{ scoringResponseCountFiltered.spaceGrantCount.toLocaleString() }}
            /
            {{ scoringResponseCountFiltered.comparisonCount.toLocaleString() }}
          </h3>
        </v-row>
        <v-row class="mb-2">
          <h3>
            Pre / Post Survey:
            {{ scoringResponseCountFiltered.preCount.toLocaleString() }}
            /
            {{ scoringResponseCountFiltered.postCount.toLocaleString() }}
          </h3>
        </v-row>
        <v-row>
          <a id="stemExposure">
            <h2>STEM Exposure</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              Students report their exposure to STEM prior to college. (Camps,
              clubs, museums, etc.)
            </p>
            <p>
              Students with scores higher than 3.5 agree that they have been
              exposed to STEM activities prior to college.
            </p>
            <p>Items included on the STEM Exposure Scale include:</p>
            <ul>
              <li>I attended a STEM focused camp or camps.</li>
              <li>
                I attended a STEM focused after school program or programs.
              </li>
              <li>I attended a STEM focused school club or clubs.</li>
              <li>I have a family member who was or is a STEM professional.</li>
              <li>
                I took at least one AP science, math, computer science or
                engineering class in high school.
              </li>
              <li>I went to a science museum or science museums.</li>
              <li>
                I read science and/or science fiction books in my free time.
              </li>
              <li>
                I watched science-related and/or science fiction TV or movies.
              </li>
              <li>I read science-related and/or science fiction books.</li>
              <li>I listened to science-related podcasts.</li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="stemExposureBarCounts"
              :layout="stemExposureBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row
          ><a id="stemExperience">
            <h2>STEM Experience</h2>
          </a></v-row
        >
        <v-row>
          <v-col cols="8">
            <p>
              Students report that they have already participated or are
              currently participating in these activities.
            </p>
          </v-col>
          <v-col cols="4">
            <div>
              <ColoredSquare class="mr-2" color="#00989D"></ColoredSquare>
              Participation
            </div>
            <br />
            <div>
              <ColoredSquare class="mr-2" color="#CB4D42"></ColoredSquare> No
              Participation
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8"> </v-col>
          <v-col cols="2">
            <h3>Space Grant Students</h3>
          </v-col>
          <v-col cols="2">
            <h3>Comparison Students</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <p>
              A STEM research or engineering project that is connected to
              ongoing work with a research or lab group or of a supervising
              researcher.
            </p>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceAwardCounts[0]"
              :layout="stemExperienceAwardLayout[0]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceComparisonCounts[0]"
              :layout="stemExperienceComparisonLayout[0]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <p>
              A multi-week STEM research or engineering project during the
              summer or academic year at your university or college.
            </p>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceAwardCounts[1]"
              :layout="stemExperienceAwardLayout[1]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceComparisonCounts[1]"
              :layout="stemExperienceComparisonLayout[1]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <p>
              A STEM design competition or challenge with other students at your
              university or college or with students at other universities or
              colleges.
            </p>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceAwardCounts[2]"
              :layout="stemExperienceAwardLayout[2]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceComparisonCounts[2]"
              :layout="stemExperienceComparisonLayout[2]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <p>
              An internship at a NASA center or facility (for example, Langley
              Research Center, Goddard Space Flight Center, or Jet Propulsion
              Laboratory).
            </p>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceAwardCounts[3]"
              :layout="stemExperienceAwardLayout[3]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceComparisonCounts[3]"
              :layout="stemExperienceComparisonLayout[3]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <p>
              An internship at a research institute, federal agency, or in
              industry.
            </p>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceAwardCounts[4]"
              :layout="stemExperienceAwardLayout[4]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceComparisonCounts[4]"
              :layout="stemExperienceComparisonLayout[4]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <p>
              Individualized guidance on your own independent undergraduate STEM
              research or engineering project.
            </p>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceAwardCounts[5]"
              :layout="stemExperienceAwardLayout[5]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceComparisonCounts[5]"
              :layout="stemExperienceComparisonLayout[5]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <p>
              Independent graduate-level STEM research or engineering project.
            </p>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceAwardCounts[6]"
              :layout="stemExperienceAwardLayout[6]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
          <v-col cols="2">
            <Plotly
              :data="stemExperienceComparisonCounts[6]"
              :layout="stemExperienceComparisonLayout[6]"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="stemIdentity">
            <h2>STEM Identity</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              STEM Identity measures how much students see themselves as STEM
              professionals by asking if they agree with the following
              statements.
            </p>
            <ul>
              <li>
                I have a strong sense of belonging to the community of STEM
                professionals.
              </li>
              <li>I feel like I belong in a STEM field.</li>
              <li>The daily work of a STEM professional is appealing to me.</li>
              <li>
                In general, being a STEM professional is an important part of my
                self-image.
              </li>
              <li>
                Being a STEM professional is an important reflection of who I
                am.
              </li>
              <li>
                I have come to think of myself as a scientist, engineer, or
                other STEM professional.
              </li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="stemIdentityBarCounts"
              :layout="stemIdentityBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="belonging">
            <h2>Belonging</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              The Belonging Scale measures how strongly students feel they are a
              part of the STEM community. Students agree or disagree with the
              following items.
            </p>
            <ul>
              <li>
                I feel included in informal networks within my
                department/program.
              </li>
              <li>I have a sense of community in my department/program.</li>
              <li>
                I have considered leaving my department/program because of
                negative experiences related to my gender and/or racial
                identity. (Reverse scored)
              </li>
              <li>I have made friends in my department/program.</li>
              <li>I’m treated like I belong in my department/program.</li>
              <li>
                In my department/program, I receive equal recognition for the
                same level of effort as other students.
              </li>
              <li>
                I feel like a respected member of my department/program
                community.
              </li>
              <li>I feel supported by my department/program.</li>
              <li>I feel valued in my department/program.</li>
              <li>
                I receive adequate support/mentoring to advance my professional
                development.
              </li>
              <li>
                My comments/ideas are taken seriously by others in my
                department/program.
              </li>
              <li>
                My gender and/or racial identity do not influence the opinions
                of others in my department/program about my abilities.
              </li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="belongingBarCounts"
              :layout="belongingBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="aspirations">
            <h2>Aspirations</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              The Aspirations Scale measures how strongly students agree that
              they have career aspirations. Students agree or disagree with the
              following items:
            </p>
            <ul>
              <li>
                I have made a definite decision about a career for myself.
              </li>
              <li>
                I am sure about what I eventually want to do for a living.
              </li>
              <li>I know what kind of job I would like to have someday.</li>
              <li>
                I am not sure what type of work I want to do when I get out of
                school. (reverse coded)
              </li>
              <li>
                I go back and forth on what career to go into. (reverse coded)
              </li>
              <li>
                I am having a difficult time choosing among different careers.
                (reverse coded)
              </li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="aspirationsBarCounts"
              :layout="aspirationsBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="academicSelfEfficacy">
            <h2>Academic Self Efficacy</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              The Academic Self Efficacy Scale measures how strongly students
              feel about their own ability to complete academic work in STEM.
              Students agree or disagree with the following items.
            </p>
            <ul>
              <li>
                I can master the content in my STEM-related courses I am taking
                this semester.
              </li>
              <li>
                I can master the content in even the most challenging STEM
                course if I try.
              </li>
              <li>
                I can do a good job on almost all of my STEM coursework if I do
                not give up.
              </li>
              <li>
                I can do an excellent job on STEM-related problems and tasks
                assigned this semester.
              </li>
              <li>
                I can learn the content taught in my STEM-related courses.
              </li>
              <li>I can earn a good grade in my STEM-related courses.</li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="academicSelfEfficacyBarCounts"
              :layout="academicSelfEfficacyBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="practicesSelfEfficacy">
            <h2>Practices Self Efficacy</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              The Practices Self Efficacy Scale measures how strongly students
              feel about their own knowledge and ability to do STEM practices.
              Students agree or disagree with the following items.
            </p>
            <ul>
              <li>Use STEM tools and/or instruments.</li>
              <li>Generate a STEM research question to answer.</li>
              <li>
                Figure out what data/observations to collect and how to collect
                them.
              </li>
              <li>
                Create explanations for the result of the study or project.
              </li>
              <li>
                Use STEM published research and/or reports to guide research or
                engineering projects.
              </li>
              <li>
                Develop theories (integrate and coordinate results from multiple
                studies).
              </li>
              <li>Design instrumentations and/or devices.</li>
              <li>Identify a design need.</li>
              <li>Develop design solutions.</li>
              <li>Evaluate a design.</li>
              <li>Recognize changes needed for a design solution to work.</li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="practicesSelfEfficacyBarCounts"
              :layout="practicesSelfEfficacyBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="contentKnowledge">
            <h2>Content Knowledge (post only)</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              The Content Knowledge Scale measures how strongly students agree
              that their STEM experience has increased their knowledge in their
              major field. Students agree or disagree with the following items:
            </p>
            <ul>
              <li>
                It increased my content knowledge about my major area of study
                (for example, biological sciences, electrical engineering).
              </li>
              <li>
                It helped me learn about research approaches in my major area of
                study.
              </li>
              <li>
                It helped me learn about the research publications in my field.
              </li>
              <li>
                It helped me improve my course grades in my major area of study.
              </li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="contentKnowledgeBarCounts"
              :layout="contentKnowledgeBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="mentoringBestPractices">
            <h2>Mentoring Best Practices (post only)</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              The Mentoring Best Practices Scale measures how strongly students
              agree that their experience with their advisor or mentor included
              elements of effective mentoring. Students agree or disagree with
              the following items:
            </p>
            <ul>
              <li>
                Interactions with my advisor or mentor were interpersonally
                rewarding.
              </li>
              <li>
                My advisor or mentor did an excellent job of mentoring during
                the program.
              </li>
              <li>
                My advisor or mentor modeled the process of scientific
                inquiry/engineering design in a manner that improved my
                understanding.
              </li>
              <li>
                My advisor or mentor was easily accessible to answer questions
                about my project or discuss research ideas.
              </li>
              <li>
                My advisor or mentor helped to minimize anxieties I had
                concerning the research/design process.
              </li>
              <li>
                My advisor or mentor supplied me with needed materials,
                information, and supplies in a timely manner.
              </li>
              <li>
                My advisor or mentor showed interest in the research I was
                conducting and/or the product I was developing.
              </li>
              <li>
                My advisor or mentor helped me plan for my future (for example,
                helped me choose classes, grad school, job.)
              </li>
              <li>
                My advisor or mentor connected me with other professional
                opportunities.
              </li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="mentoringBestPracticesBarCounts"
              :layout="mentoringBestPracticesBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="mentoringTime">
            <h2>Mentoring Time (post only)</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              Please describe how you felt about your level of contact with your
              advisor or mentor during your recent STEM experience. Students
              choose on a scale where 1 is “No Contact” and 6 is “Way too much
              contact.”
            </p>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="mentoringTimeBarCounts"
              :layout="mentoringTimeBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="engagement">
            <h2>Engagement (post only)</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              The Engagement Scale measures how strongly students agree that
              they were engaged in genuine STEM work. Students agree or disagree
              with the following items:
            </p>
            <p>My most recent STEM experience...</p>
            <ul>
              <li>Was challenging.</li>
              <li>Was fun or enjoyable.</li>
              <li>
                Provided me with opportunities to engage with peer mentors.
              </li>
              <li>Provided me with academic support or assistance.</li>
              <li>Connected me with students like myself.</li>
              <li>
                Provided me with opportunities to work together in collaborative
                projects.
              </li>
              <li>
                Provided an environment in which students help one another to
                advance towards career goals.
              </li>
              <li>Provided me with opportunities to voice my opinions.</li>
              <li>Encouraged me to pursue my interests.</li>
              <li>Helped me decide if the field is right for me.</li>
              <li>
                Helped me explore whether research and graduate school may be
                the right path for me.
              </li>
              <li>Helped me win an academic award or competition.</li>
              <li>Enhanced my resume or CV.</li>
              <li>
                Connected me to faculty who will be helpful for my future
                education or career.
              </li>
              <li>
                Helped me better understand what the job of a STEM professional
                might be like.
              </li>
              <li>
                Provided opportunities for me to network with faculty, peers, or
                other professionals.
              </li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="engagementBarCounts"
              :layout="engagementBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
        <v-row>
          <a id="autonomy">
            <h2>Autonomy (post only)</h2>
          </a>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p>
              The Autonomy Scale measures how many decisions related to the STEM
              experience were made by the student. For each item 1 is a student
              decision and 0 is a decision by anybody else.
            </p>
            <ul>
              <li>
                What research questions were asked or what problems were
                addressed
              </li>
              <li>What techniques or materials were used</li>
              <li>What to do next (for example, following interim results)</li>
              <li>What analysis methods were used</li>
              <li>How to present findings</li>
            </ul>
          </v-col>
          <v-col cols="6">
            <Plotly
              :data="autonomyBarCounts"
              :layout="autonomyBarLayout"
              :display-mode-bar="false"
            >
            </Plotly>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import DashboardNavDrawer from "../components/DashboardNavDrawer.vue";
import ColoredSquare from "../components/ColoredSquare.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";
import { Plotly } from "vue-plotly";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "DashboardScaleScores",
  components: {
    DashboardNavDrawer,
    ColoredSquare,
    Loading,
    Plotly,
  },
  data: () => ({
    isLoading: false,
    scoringResponseCount: [],
    scoringStemExperience: [],
    stemExperienceItems: [
      "A STEM research or engineering project that is connected to ongoing work with a research or lab group or of a supervising researcher.",
      "A multi-week STEM research or engineering project during the summer or academic year at your university or college.",
      "A STEM design competition or challenge with other students at your university or college or with students at other universities or colleges.",
      "An internship at a NASA center or facility (for example, Langley Research Center, Goddard Space Flight Center, or Jet Propulsion Laboratory).",
      "An internship at a research institute, federal agency, or in industry.",
      "Individualized guidance on your own independent undergraduate STEM research or engineering project.",
      "Independent graduate-level STEM research or engineering project.",
    ],
    stemExperienceAwardCounts: [],
    stemExperienceAwardLayout: [],
    stemExperienceComparisonCounts: [],
    stemExperienceComparisonLayout: [],
    scoringStemIdentity: [],
    stemIdentityBarCounts: [],
    stemIdentityBarLayout: {},
    // scoringStemProfessionalIdentity: [],
    // stemProfessionalIdentityOverallCounts: [],
    // stemProfessionalIdentityOverallLayout: {},
    // stemProfessionalIdentityBarCounts: [],
    // stemProfessionalIdentityBarLayout: {},
    scoringStemExposure: [],
    stemExposureBarCounts: [],
    stemExposureBarLayout: {},
    scoringBelonging: [],
    belongingBarCounts: [],
    belongingBarLayout: {},
    scoringAspirations: [],
    aspirationsBarCounts: [],
    aspirationsBarLayout: {},
    scoringAcademicSelfEfficacy: [],
    academicSelfEfficacyBarCounts: [],
    academicSelfEfficacyBarLayout: {},
    scoringPracticesSelfEfficacy: [],
    practicesSelfEfficacyBarCounts: [],
    practicesSelfEfficacyBarLayout: {},
    contentKnowledgeBarCounts: [],
    contentKnowledgeBarLayout: {},
    mentoringBestPracticesBarCounts: [],
    mentoringBestPracticesBarLayout: {},
    mentoringTimeBarCounts: [],
    mentoringTimeBarLayout: {},
    engagementBarCounts: [],
    engagementBarLayout: {},
    autonomyBarCounts: [],
    autonomyBarLayout: {},
    stateFilter: null,
    regionFilter: null,
    statesFilter: [],
    refState: [],
    refStateRegion: [],
  }),
  computed: {
    scoringResponseCountFiltered: function () {
      const srcf = this.scoringResponseCount
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
      const totalCount = srcf
        .map((e) => e.count)
        .reduce((pv, cv) => pv + cv, 0);
      const spaceGrantCount = srcf
        .filter((e) => e.award_status === "award")
        .map((e) => e.count)
        .reduce((pv, cv) => pv + cv, 0);
      const comparisonCount = srcf
        .filter((e) => e.award_status !== "award")
        .map((e) => e.count)
        .reduce((pv, cv) => pv + cv, 0);
      const preCount = srcf
        .filter((e) => e.survey === "pre")
        .map((e) => e.count)
        .reduce((pv, cv) => pv + cv, 0);
      const postCount = srcf
        .filter((e) => e.survey === "post")
        .map((e) => e.count)
        .reduce((pv, cv) => pv + cv, 0);

      return {
        totalCount: totalCount,
        spaceGrantCount: spaceGrantCount,
        comparisonCount: comparisonCount,
        preCount: preCount,
        postCount: postCount,
      };
    },
    scoringStemExperienceFiltered: function () {
      return this.scoringStemExperience
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringStemIdentityFiltered: function () {
      return this.scoringStemIdentity
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    // scoringStemProfessionalIdentityFiltered: function () {
    //   return this.scoringStemProfessionalIdentity
    //     .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
    //     .filter(
    //       (obj) => !this.regionFilter || obj.region === this.regionFilter
    //     );
    // },
    scoringStemExposureFiltered: function () {
      return this.scoringStemExposure
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringBelongingFiltered: function () {
      return this.scoringBelonging
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringAspirationsFiltered: function () {
      return this.scoringAspirations
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringAcademicSelfEfficacyFiltered: function () {
      return this.scoringAcademicSelfEfficacy
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringPracticesSelfEfficacyFiltered: function () {
      return this.scoringPracticesSelfEfficacy
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringContentKnowledgeFiltered: function () {
      return this.scoringContentKnowledge
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringMentoringBestPracticesFiltered: function () {
      return this.scoringMentoringBestPractices
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringMentoringTimeFiltered: function () {
      return this.scoringMentoringTime
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringEngagementFiltered: function () {
      return this.scoringEngagement
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
    scoringAutonomyFiltered: function () {
      return this.scoringAutonomy
        .filter((obj) => !this.stateFilter || obj.state === this.stateFilter)
        .filter(
          (obj) => !this.regionFilter || obj.region === this.regionFilter
        );
    },
  },
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      this.isLoading = true;
      let localData = 0;
      console.log("before localforage promise");
      await Promise.all([
        localforage
          .getItem("refState")
          .then((v) => {
            if (v.length) {
              this.refState = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(`error loading refState from localforage: ${e}`)
          ),
        localforage
          .getItem("refStateRegion")
          .then((v) => {
            if (v.length) {
              this.refStateRegion = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(`error loading refStateRegion from localforage: ${e}`)
          ),
        localforage
          .getItem("scoringResponseCount")
          .then((v) => {
            if (v.length) {
              this.scoringResponseCount = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringResponseCount from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringStemExperience")
          .then((v) => {
            if (v.length) {
              this.scoringStemExperience = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringStemExperience from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringStemIdentity")
          .then((v) => {
            if (v.length) {
              this.scoringStemIdentity = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringStemIdentity from localforage: ${e}`
            )
          ),
        // localforage
        //   .getItem("scoringStemProfessionalIdentity")
        //   .then((v) => {
        //     if (v.length) {
        //       this.scoringStemProfessionalIdentity = v;
        //       localData += 1;
        //     }
        //   })
        //   .catch((e) =>
        //     console.log(
        //       `error loading scoringStemProfessionalIdentity from localforage: ${e}`
        //     )
        //   ),
        localforage
          .getItem("scoringStemExposure")
          .then((v) => {
            if (v.length) {
              this.scoringStemExposure = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringStemExposure from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringBelonging")
          .then((v) => {
            if (v.length) {
              this.scoringBelonging = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(`error loading scoringBelonging from localforage: ${e}`)
          ),
        localforage
          .getItem("scoringAspirations")
          .then((v) => {
            if (v.length) {
              this.scoringAspirations = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringAspirations from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringAcademicSelfEfficacy")
          .then((v) => {
            if (v.length) {
              this.scoringAcademicSelfEfficacy = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringAcademicSelfEfficacy from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringPracticesSelfEfficacy")
          .then((v) => {
            if (v.length) {
              this.scoringPracticesSelfEfficacy = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringPracticesSelfEfficacy from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringContentKnowledge")
          .then((v) => {
            if (v.length) {
              this.scoringContentKnowledge = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringContentKnowledge from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringMentoringBestPractices")
          .then((v) => {
            if (v.length) {
              this.scoringMentoringBestPractices = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringMentoringBestPractices from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringMentoringTime")
          .then((v) => {
            if (v.length) {
              this.scoringMentoringTime = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringMentoringTime from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringEngagement")
          .then((v) => {
            if (v.length) {
              this.scoringEngagement = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(
              `error loading scoringEngagement from localforage: ${e}`
            )
          ),
        localforage
          .getItem("scoringAutonomy")
          .then((v) => {
            if (v.length) {
              this.scoringAutonomy = v;
              localData += 1;
            }
          })
          .catch((e) =>
            console.log(`error loading scoringAutonomy from localforage: ${e}`)
          ),
      ]);
      console.log("after localforage promise");
      if (localData === 3) {
        console.log("retrieved all data");
        this.populatePlotData();
        this.isLoading = false;
      }
      console.log("before database promise");
      await Promise.all([
        this.getApiData("/ref_state/", "refState", this.noFilter, this.noSort),
        this.getApiData(
          "/ref_state_region/",
          "refStateRegion",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_response_count/",
          "scoringResponseCount",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_stem_experience/",
          "scoringStemExperience",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_stem_identity/",
          "scoringStemIdentity",
          this.noFilter,
          this.noSort
        ),
        // this.getApiData(
        //   "/scoring_stem_professional_identity/",
        //   "scoringStemProfessionalIdentity",
        //   this.noFilter,
        //   this.noSort
        // ),
        this.getApiData(
          "/scoring_stem_exposure/",
          "scoringStemExposure",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_belonging/",
          "scoringBelonging",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_aspirations/",
          "scoringAspirations",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_academic_self_efficacy/",
          "scoringAcademicSelfEfficacy",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_practices_self_efficacy/",
          "scoringPracticesSelfEfficacy",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_content_knowledge/",
          "scoringContentKnowledge",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_mentoring_best_practices/",
          "scoringMentoringBestPractices",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_mentoring_time/",
          "scoringMentoringTime",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_engagement/",
          "scoringEngagement",
          this.noFilter,
          this.noSort
        ),
        this.getApiData(
          "/scoring_autonomy/",
          "scoringAutonomy",
          this.noFilter,
          this.noSort
        ),
      ]);
      console.log("after database promise");
      this.updateLocalData();
      this.populatePlotData();
      this.isLoading = false;
    }
  },
  methods: {
    populatePlotData: function () {
      this.populateStemExperiencePlots();
      this.populateStemIdentityPlots();
      // this.populateStemProfessionalIdentityPlots();
      this.populateStemExposurePlots();
      this.populateBelongingPlots();
      this.populateAspirationsPlots();
      this.populateAcademicSelfEfficacyPlots();
      this.populatePracticesSelfEfficacyPlots();
      this.populateContentKnowledgePlots();
      this.populateMentoringBestPracticesPlots();
      this.populateMentoringTimePlots();
      this.populateEngagementPlots();
      this.populateAutonomyPlots();
    },
    populateStemExperiencePlots: function () {
      const margin_t = 0;
      const margin_b = 0;
      const margin_l = 0;
      const margin_r = 0;
      const plot_height = 200;

      this.stemExperienceAwardCounts = [];
      this.stemExperienceAwardLayout = [];
      this.stemExperienceComparisonCounts = [];
      this.stemExperienceComparisonLayout = [];

      this.stemExperienceItems.forEach((i) => {
        const stemExperienceAwardYes = this.scoringStemExperienceFiltered
          .filter((e) => e.award_status === "award" && e.item === i)
          .map((e) => e.yes)
          .reduce((pv, cv) => pv + cv, 0);
        const stemExperienceAwardTotal = this.scoringStemExperienceFiltered
          .filter((e) => e.award_status === "award" && e.item === i)
          .map((e) => e.total)
          .reduce((pv, cv) => pv + cv, 0);
        const stemExperienceAwardNo =
          stemExperienceAwardTotal - stemExperienceAwardYes;
        const stemExperienceAwardCounts = [
          {
            values: [stemExperienceAwardYes, stemExperienceAwardNo],
            labels: ["Participation", "No Participation"],
            type: "pie",
            sort: false,
            hole: 0.4,
            marker: {
              colors: ["#00989D", "#CB4D42"],
            },
          },
        ];
        const stemExperienceAwardLayout = {
          showlegend: false,
          height: plot_height,
          margin: {
            t: margin_t,
            b: margin_b,
            l: margin_l,
            r: margin_r,
          },
        };
        this.stemExperienceAwardCounts.push(stemExperienceAwardCounts);
        this.stemExperienceAwardLayout.push(stemExperienceAwardLayout);

        const stemExperienceComparisonYes = this.scoringStemExperienceFiltered
          .filter((e) => e.award_status !== "award" && e.item === i)
          .map((e) => e.yes)
          .reduce((pv, cv) => pv + cv, 0);
        const stemExperienceComparisonTotal = this.scoringStemExperienceFiltered
          .filter((e) => e.award_status !== "award" && e.item === i)
          .map((e) => e.total)
          .reduce((pv, cv) => pv + cv, 0);
        const stemExperienceComparisonNo =
          stemExperienceComparisonTotal - stemExperienceComparisonYes;
        const stemExperienceComparisonCounts = [
          {
            values: [stemExperienceComparisonYes, stemExperienceComparisonNo],
            labels: ["Participation", "No Participation"],
            type: "pie",
            sort: false,
            hole: 0.4,
            marker: {
              colors: ["#00989D", "#CB4D42"],
            },
          },
        ];
        const stemExperienceComparisonLayout = {
          showlegend: false,
          height: plot_height,
          margin: {
            t: margin_t,
            b: margin_b,
            l: margin_l,
            r: margin_r,
          },
        };
        this.stemExperienceComparisonCounts.push(
          stemExperienceComparisonCounts
        );
        this.stemExperienceComparisonLayout.push(
          stemExperienceComparisonLayout
        );
      });
    },
    populateStemIdentityPlots: function () {
      const stemIdentityAwardPostNSum = this.scoringStemIdentityFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityAwardPostMeanXNSum = this.scoringStemIdentityFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityAwardPostMeanScore =
        stemIdentityAwardPostMeanXNSum / stemIdentityAwardPostNSum;
      const stemIdentityAwardPreNSum = this.scoringStemIdentityFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityAwardPreMeanXNSum = this.scoringStemIdentityFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityAwardPreMeanScore =
        stemIdentityAwardPreMeanXNSum / stemIdentityAwardPreNSum;

      const stemIdentityComparisonPostNSum = this.scoringStemIdentityFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityComparisonPostMeanXNSum =
        this.scoringStemIdentityFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityComparisonPostMeanScore =
        stemIdentityComparisonPostMeanXNSum / stemIdentityComparisonPostNSum;
      const stemIdentityComparisonPreNSum = this.scoringStemIdentityFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityComparisonPreMeanXNSum =
        this.scoringStemIdentityFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityComparisonPreMeanScore =
        stemIdentityComparisonPreMeanXNSum / stemIdentityComparisonPreNSum;

      const stemIdentityFemalePostNSum = this.scoringStemIdentityFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityFemalePostMeanXNSum = this.scoringStemIdentityFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityFemalePostMeanScore =
        stemIdentityFemalePostMeanXNSum / stemIdentityFemalePostNSum;
      const stemIdentityFemalePreNSum = this.scoringStemIdentityFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityFemalePreMeanXNSum = this.scoringStemIdentityFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityFemalePreMeanScore =
        stemIdentityFemalePreMeanXNSum / stemIdentityFemalePreNSum;

      const stemIdentityUnderrepPostNSum = this.scoringStemIdentityFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityUnderrepPostMeanXNSum = this.scoringStemIdentityFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityUnderrepPostMeanScore =
        stemIdentityUnderrepPostMeanXNSum / stemIdentityUnderrepPostNSum;
      const stemIdentityUnderrepPreNSum = this.scoringStemIdentityFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityUnderrepPreMeanXNSum = this.scoringStemIdentityFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemIdentityUnderrepPreMeanScore =
        stemIdentityUnderrepPreMeanXNSum / stemIdentityUnderrepPreNSum;

      const stemIdentityBarPostNSums = {
        x: [
          stemIdentityComparisonPostMeanScore,
          stemIdentityUnderrepPostMeanScore,
          stemIdentityFemalePostMeanScore,
          stemIdentityAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          stemIdentityComparisonPostMeanScore,
          stemIdentityUnderrepPostMeanScore,
          stemIdentityFemalePostMeanScore,
          stemIdentityAwardPostMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const stemIdentityBarPreNSums = {
        x: [
          stemIdentityComparisonPreMeanScore,
          stemIdentityUnderrepPreMeanScore,
          stemIdentityFemalePreMeanScore,
          stemIdentityAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          stemIdentityComparisonPreMeanScore,
          stemIdentityUnderrepPreMeanScore,
          stemIdentityFemalePreMeanScore,
          stemIdentityAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.stemIdentityBarCounts = [
        stemIdentityBarPostNSums,
        stemIdentityBarPreNSums,
      ];
      this.stemIdentityBarLayout = {
        title: "Stem Identity",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 6],
          tickvals: [1, 2, 3, 4, 5, 6],
          ticktext: [
            "1<br>Strongly<br>Disagree",
            "2",
            "3",
            "4",
            "5",
            "6<br>Strongly<br>Agree",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    // populateStemProfessionalIdentityPlots: function () {
    //   const stemProfessionalIdentityOverallTotalCount =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .map((e) => e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityOverallOneCount =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .map((e) => e.response_1_count)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityOverallTwoCount =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .map((e) => e.response_2_count)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityOverallThreeCount =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .map((e) => e.response_3_count)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityOverallFourCount =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .map((e) => e.response_4_count)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityOverallFiveCount =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .map((e) => e.response_5_count)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityOverallSixCount =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .map((e) => e.response_6_count)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityOverallSevenCount =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .map((e) => e.response_7_count)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityOverallMean =
    //     (1 * stemProfessionalIdentityOverallOneCount +
    //       2 * stemProfessionalIdentityOverallTwoCount +
    //       3 * stemProfessionalIdentityOverallThreeCount +
    //       4 * stemProfessionalIdentityOverallFourCount +
    //       5 * stemProfessionalIdentityOverallFiveCount +
    //       6 * stemProfessionalIdentityOverallSixCount +
    //       7 * stemProfessionalIdentityOverallSevenCount) /
    //     stemProfessionalIdentityOverallTotalCount;
    //   this.stemProfessionalIdentityOverallCounts = [
    //     {
    //       values: [
    //         stemProfessionalIdentityOverallOneCount,
    //         stemProfessionalIdentityOverallTwoCount,
    //         stemProfessionalIdentityOverallThreeCount,
    //         stemProfessionalIdentityOverallFourCount,
    //         stemProfessionalIdentityOverallFiveCount,
    //         stemProfessionalIdentityOverallSixCount,
    //         stemProfessionalIdentityOverallSevenCount,
    //       ],
    //       labels: ["1", "2", "3", "4", "5", "6", "7"],
    //       type: "pie",
    //       sort: false,
    //       direction: "clockwise",
    //       hole: 0.4,
    //       textinfo: "value+percent",
    //       marker: {
    //         // library(scales); hue_pal(l = 50)(7)
    //         colors: [
    //           "#CB4D42",
    //           "#9A7300",
    //           "#208C00",
    //           "#00986D",
    //           "#0090C4",
    //           "#7F60E3",
    //           "#D327B0",
    //         ],
    //       },
    //     },
    //   ];
    //   this.stemProfessionalIdentityOverallLayout = {
    //     title: "Overall",
    //     annotations: [
    //       {
    //         font: {
    //           size: 20,
    //         },
    //         text: stemProfessionalIdentityOverallMean.toFixed(1),
    //         x: 0.5,
    //         y: 0.5,
    //         showarrow: false,
    //       },
    //     ],
    //   };
    //
    //   const stemProfessionalIdentityAwardYesNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.award_status === "award")
    //       .map((e) => e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityAwardYesMeanXNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.award_status === "award")
    //       .map((e) => e.mean_response * e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityAwardYesMeanScore =
    //     stemProfessionalIdentityAwardYesMeanXNSum /
    //     stemProfessionalIdentityAwardYesNSum;
    //   const stemProfessionalIdentityAwardNoNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.award_status !== "award")
    //       .map((e) => e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityAwardNoMeanXNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.award_status !== "award")
    //       .map((e) => e.mean_response * e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityAwardNoMeanScore =
    //     stemProfessionalIdentityAwardNoMeanXNSum /
    //     stemProfessionalIdentityAwardNoNSum;
    //   const stemProfessionalIdentityFemaleYesNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.female === "Yes")
    //       .map((e) => e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityFemaleYesMeanXNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.female === "Yes")
    //       .map((e) => e.mean_response * e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityFemaleYesMeanScore =
    //     stemProfessionalIdentityFemaleYesMeanXNSum /
    //     stemProfessionalIdentityFemaleYesNSum;
    //   const stemProfessionalIdentityFemaleNoNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.female !== "Yes")
    //       .map((e) => e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityFemaleNoMeanXNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.female !== "Yes")
    //       .map((e) => e.mean_response * e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityFemaleNoMeanScore =
    //     stemProfessionalIdentityFemaleNoMeanXNSum /
    //     stemProfessionalIdentityFemaleNoNSum;
    //   const stemProfessionalIdentityUnderrepYesNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.white === "No")
    //       .map((e) => e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityUnderrepYesMeanXNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.white === "No")
    //       .map((e) => e.mean_response * e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityUnderrepYesMeanScore =
    //     stemProfessionalIdentityUnderrepYesMeanXNSum /
    //     stemProfessionalIdentityUnderrepYesNSum;
    //   const stemProfessionalIdentityUnderrepNoNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.white !== "No")
    //       .map((e) => e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityUnderrepNoMeanXNSum =
    //     this.scoringStemProfessionalIdentityFiltered
    //       .filter((e) => e.white !== "No")
    //       .map((e) => e.mean_response * e.n_responses)
    //       .reduce((pv, cv) => pv + cv, 0);
    //   const stemProfessionalIdentityUnderrepNoMeanScore =
    //     stemProfessionalIdentityUnderrepNoMeanXNSum /
    //     stemProfessionalIdentityUnderrepNoNSum;
    //   const stemProfessionalIdentityBarYesNSums = {
    //     y: [
    //       stemProfessionalIdentityAwardYesMeanScore,
    //       stemProfessionalIdentityFemaleYesMeanScore,
    //       stemProfessionalIdentityUnderrepYesMeanScore,
    //     ],
    //     x: ["Award", "Female", "Under-represented"],
    //     type: "bar",
    //     name: "Yes",
    //     marker: {
    //       color: "#CB4D42",
    //     },
    //   };
    //   const stemProfessionalIdentityBarNoNSums = {
    //     y: [
    //       stemProfessionalIdentityAwardNoMeanScore,
    //       stemProfessionalIdentityFemaleNoMeanScore,
    //       stemProfessionalIdentityUnderrepNoMeanScore,
    //     ],
    //     x: ["Award", "Female", "Under-represented"],
    //     type: "bar",
    //     name: "No",
    //     marker: {
    //       color: "#00989D",
    //     },
    //   };
    //   this.stemProfessionalIdentityBarCounts = [
    //     stemProfessionalIdentityBarYesNSums,
    //     stemProfessionalIdentityBarNoNSums,
    //   ];
    //   this.stemProfessionalIdentityBarLayout = {
    //     title: "By Group",
    //     yaxis: {
    //       title: "STEM Professional Identity (%)",
    //     },
    //     barmode: "group",
    //   };
    // },
    populateStemExposurePlots: function () {
      const stemExposureAwardPostNSum = this.scoringStemExposureFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureAwardPostMeanXNSum = this.scoringStemExposureFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureAwardPostMeanScore =
        stemExposureAwardPostMeanXNSum / stemExposureAwardPostNSum;
      const stemExposureAwardPreNSum = this.scoringStemExposureFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureAwardPreMeanXNSum = this.scoringStemExposureFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureAwardPreMeanScore =
        stemExposureAwardPreMeanXNSum / stemExposureAwardPreNSum;

      const stemExposureComparisonPostNSum = this.scoringStemExposureFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureComparisonPostMeanXNSum =
        this.scoringStemExposureFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const stemExposureComparisonPostMeanScore =
        stemExposureComparisonPostMeanXNSum / stemExposureComparisonPostNSum;
      const stemExposureComparisonPreNSum = this.scoringStemExposureFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureComparisonPreMeanXNSum =
        this.scoringStemExposureFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const stemExposureComparisonPreMeanScore =
        stemExposureComparisonPreMeanXNSum / stemExposureComparisonPreNSum;

      const stemExposureFemalePostNSum = this.scoringStemExposureFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureFemalePostMeanXNSum = this.scoringStemExposureFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureFemalePostMeanScore =
        stemExposureFemalePostMeanXNSum / stemExposureFemalePostNSum;
      const stemExposureFemalePreNSum = this.scoringStemExposureFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureFemalePreMeanXNSum = this.scoringStemExposureFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureFemalePreMeanScore =
        stemExposureFemalePreMeanXNSum / stemExposureFemalePreNSum;

      const stemExposureUnderrepPostNSum = this.scoringStemExposureFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureUnderrepPostMeanXNSum = this.scoringStemExposureFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureUnderrepPostMeanScore =
        stemExposureUnderrepPostMeanXNSum / stemExposureUnderrepPostNSum;
      const stemExposureUnderrepPreNSum = this.scoringStemExposureFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureUnderrepPreMeanXNSum = this.scoringStemExposureFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const stemExposureUnderrepPreMeanScore =
        stemExposureUnderrepPreMeanXNSum / stemExposureUnderrepPreNSum;

      const stemExposureBarPostNSums = {
        x: [
          stemExposureComparisonPostMeanScore,
          stemExposureUnderrepPostMeanScore,
          stemExposureFemalePostMeanScore,
          stemExposureAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        marker: {
          color: "#CB4D42",
        },
      };
      const stemExposureBarPreNSums = {
        x: [
          stemExposureComparisonPreMeanScore,
          stemExposureUnderrepPreMeanScore,
          stemExposureFemalePreMeanScore,
          stemExposureAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          stemExposureComparisonPreMeanScore,
          stemExposureUnderrepPreMeanScore,
          stemExposureFemalePreMeanScore,
          stemExposureAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.stemExposureBarCounts = [
        stemExposureBarPostNSums,
        stemExposureBarPreNSums,
      ];
      this.stemExposureBarLayout = {
        title: "Stem Exposure",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 6],
          tickvals: [1, 2, 3, 4, 5, 6],
          ticktext: [
            "1<br>Strongly<br>Disagree",
            "2",
            "3",
            "4",
            "5",
            "6<br>Strongly<br>Agree",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    populateBelongingPlots: function () {
      const belongingAwardPostNSum = this.scoringBelongingFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingAwardPostMeanXNSum = this.scoringBelongingFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingAwardPostMeanScore =
        belongingAwardPostMeanXNSum / belongingAwardPostNSum;
      const belongingAwardPreNSum = this.scoringBelongingFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingAwardPreMeanXNSum = this.scoringBelongingFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingAwardPreMeanScore =
        belongingAwardPreMeanXNSum / belongingAwardPreNSum;

      const belongingComparisonPostNSum = this.scoringBelongingFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingComparisonPostMeanXNSum = this.scoringBelongingFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingComparisonPostMeanScore =
        belongingComparisonPostMeanXNSum / belongingComparisonPostNSum;
      const belongingComparisonPreNSum = this.scoringBelongingFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingComparisonPreMeanXNSum = this.scoringBelongingFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingComparisonPreMeanScore =
        belongingComparisonPreMeanXNSum / belongingComparisonPreNSum;

      const belongingFemalePostNSum = this.scoringBelongingFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingFemalePostMeanXNSum = this.scoringBelongingFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingFemalePostMeanScore =
        belongingFemalePostMeanXNSum / belongingFemalePostNSum;
      const belongingFemalePreNSum = this.scoringBelongingFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingFemalePreMeanXNSum = this.scoringBelongingFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingFemalePreMeanScore =
        belongingFemalePreMeanXNSum / belongingFemalePreNSum;

      const belongingUnderrepPostNSum = this.scoringBelongingFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingUnderrepPostMeanXNSum = this.scoringBelongingFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingUnderrepPostMeanScore =
        belongingUnderrepPostMeanXNSum / belongingUnderrepPostNSum;
      const belongingUnderrepPreNSum = this.scoringBelongingFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingUnderrepPreMeanXNSum = this.scoringBelongingFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const belongingUnderrepPreMeanScore =
        belongingUnderrepPreMeanXNSum / belongingUnderrepPreNSum;

      const belongingBarPostNSums = {
        x: [
          belongingComparisonPostMeanScore,
          belongingUnderrepPostMeanScore,
          belongingFemalePostMeanScore,
          belongingAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          belongingComparisonPostMeanScore,
          belongingUnderrepPostMeanScore,
          belongingFemalePostMeanScore,
          belongingAwardPostMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const belongingBarPreNSums = {
        x: [
          belongingComparisonPreMeanScore,
          belongingUnderrepPreMeanScore,
          belongingFemalePreMeanScore,
          belongingAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          belongingComparisonPreMeanScore,
          belongingUnderrepPreMeanScore,
          belongingFemalePreMeanScore,
          belongingAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.belongingBarCounts = [belongingBarPostNSums, belongingBarPreNSums];
      this.belongingBarLayout = {
        title: "Belonging",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 6],
          tickvals: [1, 2, 3, 4, 5, 6],
          ticktext: [
            "1<br>Strongly<br>Disagree",
            "2",
            "3",
            "4",
            "5",
            "6<br>Strongly<br>Agree",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    populateAspirationsPlots: function () {
      const aspirationsAwardPostNSum = this.scoringAspirationsFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsAwardPostMeanXNSum = this.scoringAspirationsFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsAwardPostMeanScore =
        aspirationsAwardPostMeanXNSum / aspirationsAwardPostNSum;
      const aspirationsAwardPreNSum = this.scoringAspirationsFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsAwardPreMeanXNSum = this.scoringAspirationsFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsAwardPreMeanScore =
        aspirationsAwardPreMeanXNSum / aspirationsAwardPreNSum;

      const aspirationsComparisonPostNSum = this.scoringAspirationsFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsComparisonPostMeanXNSum = this.scoringAspirationsFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsComparisonPostMeanScore =
        aspirationsComparisonPostMeanXNSum / aspirationsComparisonPostNSum;
      const aspirationsComparisonPreNSum = this.scoringAspirationsFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsComparisonPreMeanXNSum = this.scoringAspirationsFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsComparisonPreMeanScore =
        aspirationsComparisonPreMeanXNSum / aspirationsComparisonPreNSum;

      const aspirationsFemalePostNSum = this.scoringAspirationsFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsFemalePostMeanXNSum = this.scoringAspirationsFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsFemalePostMeanScore =
        aspirationsFemalePostMeanXNSum / aspirationsFemalePostNSum;
      const aspirationsFemalePreNSum = this.scoringAspirationsFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsFemalePreMeanXNSum = this.scoringAspirationsFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsFemalePreMeanScore =
        aspirationsFemalePreMeanXNSum / aspirationsFemalePreNSum;

      const aspirationsUnderrepPostNSum = this.scoringAspirationsFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsUnderrepPostMeanXNSum = this.scoringAspirationsFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsUnderrepPostMeanScore =
        aspirationsUnderrepPostMeanXNSum / aspirationsUnderrepPostNSum;
      const aspirationsUnderrepPreNSum = this.scoringAspirationsFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsUnderrepPreMeanXNSum = this.scoringAspirationsFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const aspirationsUnderrepPreMeanScore =
        aspirationsUnderrepPreMeanXNSum / aspirationsUnderrepPreNSum;

      const aspirationsBarPostNSums = {
        x: [
          aspirationsComparisonPostMeanScore,
          aspirationsUnderrepPostMeanScore,
          aspirationsFemalePostMeanScore,
          aspirationsAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          aspirationsComparisonPostMeanScore,
          aspirationsUnderrepPostMeanScore,
          aspirationsFemalePostMeanScore,
          aspirationsAwardPostMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const aspirationsBarPreNSums = {
        x: [
          aspirationsComparisonPreMeanScore,
          aspirationsUnderrepPreMeanScore,
          aspirationsFemalePreMeanScore,
          aspirationsAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          aspirationsComparisonPreMeanScore,
          aspirationsUnderrepPreMeanScore,
          aspirationsFemalePreMeanScore,
          aspirationsAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.aspirationsBarCounts = [
        aspirationsBarPostNSums,
        aspirationsBarPreNSums,
      ];
      this.aspirationsBarLayout = {
        title: "Aspirations",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 7],
          tickvals: [1, 2, 3, 4, 5, 6, 7],
          ticktext: [
            "1<br>Strongly<br>Disagree",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7<br>Strongly<br>Agree",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    populateAcademicSelfEfficacyPlots: function () {
      const academicSelfEfficacyAwardPostNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter((e) => e.award_status === "award" && e.survey === "post")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyAwardPostMeanXNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter((e) => e.award_status === "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyAwardPostMeanScore =
        academicSelfEfficacyAwardPostMeanXNSum /
        academicSelfEfficacyAwardPostNSum;
      const academicSelfEfficacyAwardPreNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter((e) => e.award_status === "award" && e.survey === "pre")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyAwardPreMeanXNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter((e) => e.award_status === "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyAwardPreMeanScore =
        academicSelfEfficacyAwardPreMeanXNSum /
        academicSelfEfficacyAwardPreNSum;

      const academicSelfEfficacyComparisonPostNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyComparisonPostMeanXNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyComparisonPostMeanScore =
        academicSelfEfficacyComparisonPostMeanXNSum /
        academicSelfEfficacyComparisonPostNSum;
      const academicSelfEfficacyComparisonPreNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyComparisonPreMeanXNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyComparisonPreMeanScore =
        academicSelfEfficacyComparisonPreMeanXNSum /
        academicSelfEfficacyComparisonPreNSum;

      const academicSelfEfficacyFemalePostNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyFemalePostMeanXNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyFemalePostMeanScore =
        academicSelfEfficacyFemalePostMeanXNSum /
        academicSelfEfficacyFemalePostNSum;
      const academicSelfEfficacyFemalePreNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyFemalePreMeanXNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyFemalePreMeanScore =
        academicSelfEfficacyFemalePreMeanXNSum /
        academicSelfEfficacyFemalePreNSum;

      const academicSelfEfficacyUnderrepPostNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyUnderrepPostMeanXNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyUnderrepPostMeanScore =
        academicSelfEfficacyUnderrepPostMeanXNSum /
        academicSelfEfficacyUnderrepPostNSum;
      const academicSelfEfficacyUnderrepPreNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyUnderrepPreMeanXNSum =
        this.scoringAcademicSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const academicSelfEfficacyUnderrepPreMeanScore =
        academicSelfEfficacyUnderrepPreMeanXNSum /
        academicSelfEfficacyUnderrepPreNSum;

      const academicSelfEfficacyBarPostNSums = {
        x: [
          academicSelfEfficacyComparisonPostMeanScore,
          academicSelfEfficacyUnderrepPostMeanScore,
          academicSelfEfficacyFemalePostMeanScore,
          academicSelfEfficacyAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          academicSelfEfficacyComparisonPostMeanScore,
          academicSelfEfficacyUnderrepPostMeanScore,
          academicSelfEfficacyFemalePostMeanScore,
          academicSelfEfficacyAwardPostMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const academicSelfEfficacyBarPreNSums = {
        x: [
          academicSelfEfficacyComparisonPreMeanScore,
          academicSelfEfficacyUnderrepPreMeanScore,
          academicSelfEfficacyFemalePreMeanScore,
          academicSelfEfficacyAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          academicSelfEfficacyComparisonPreMeanScore,
          academicSelfEfficacyUnderrepPreMeanScore,
          academicSelfEfficacyFemalePreMeanScore,
          academicSelfEfficacyAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.academicSelfEfficacyBarCounts = [
        academicSelfEfficacyBarPostNSums,
        academicSelfEfficacyBarPreNSums,
      ];
      this.academicSelfEfficacyBarLayout = {
        title: "Academic Self Efficacy",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 6],
          tickvals: [1, 2, 3, 4, 5, 6],
          ticktext: [
            "1<br>Strongly<br>Disagree",
            "2",
            "3",
            "4",
            "5",
            "6<br>Strongly<br>Agree",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    populatePracticesSelfEfficacyPlots: function () {
      const practicesSelfEfficacyAwardPostNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter((e) => e.award_status === "award" && e.survey === "post")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyAwardPostMeanXNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter((e) => e.award_status === "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyAwardPostMeanScore =
        practicesSelfEfficacyAwardPostMeanXNSum /
        practicesSelfEfficacyAwardPostNSum;
      const practicesSelfEfficacyAwardPreNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter((e) => e.award_status === "award" && e.survey === "pre")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyAwardPreMeanXNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter((e) => e.award_status === "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyAwardPreMeanScore =
        practicesSelfEfficacyAwardPreMeanXNSum /
        practicesSelfEfficacyAwardPreNSum;

      const practicesSelfEfficacyComparisonPostNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyComparisonPostMeanXNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyComparisonPostMeanScore =
        practicesSelfEfficacyComparisonPostMeanXNSum /
        practicesSelfEfficacyComparisonPostNSum;
      const practicesSelfEfficacyComparisonPreNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyComparisonPreMeanXNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyComparisonPreMeanScore =
        practicesSelfEfficacyComparisonPreMeanXNSum /
        practicesSelfEfficacyComparisonPreNSum;

      const practicesSelfEfficacyFemalePostNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyFemalePostMeanXNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyFemalePostMeanScore =
        practicesSelfEfficacyFemalePostMeanXNSum /
        practicesSelfEfficacyFemalePostNSum;
      const practicesSelfEfficacyFemalePreNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyFemalePreMeanXNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyFemalePreMeanScore =
        practicesSelfEfficacyFemalePreMeanXNSum /
        practicesSelfEfficacyFemalePreNSum;

      const practicesSelfEfficacyUnderrepPostNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyUnderrepPostMeanXNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyUnderrepPostMeanScore =
        practicesSelfEfficacyUnderrepPostMeanXNSum /
        practicesSelfEfficacyUnderrepPostNSum;
      const practicesSelfEfficacyUnderrepPreNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyUnderrepPreMeanXNSum =
        this.scoringPracticesSelfEfficacyFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const practicesSelfEfficacyUnderrepPreMeanScore =
        practicesSelfEfficacyUnderrepPreMeanXNSum /
        practicesSelfEfficacyUnderrepPreNSum;

      const practicesSelfEfficacyBarPostNSums = {
        x: [
          practicesSelfEfficacyComparisonPostMeanScore,
          practicesSelfEfficacyUnderrepPostMeanScore,
          practicesSelfEfficacyFemalePostMeanScore,
          practicesSelfEfficacyAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          practicesSelfEfficacyComparisonPostMeanScore,
          practicesSelfEfficacyUnderrepPostMeanScore,
          practicesSelfEfficacyFemalePostMeanScore,
          practicesSelfEfficacyAwardPostMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const practicesSelfEfficacyBarPreNSums = {
        x: [
          practicesSelfEfficacyComparisonPreMeanScore,
          practicesSelfEfficacyUnderrepPreMeanScore,
          practicesSelfEfficacyFemalePreMeanScore,
          practicesSelfEfficacyAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          practicesSelfEfficacyComparisonPreMeanScore,
          practicesSelfEfficacyUnderrepPreMeanScore,
          practicesSelfEfficacyFemalePreMeanScore,
          practicesSelfEfficacyAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.practicesSelfEfficacyBarCounts = [
        practicesSelfEfficacyBarPostNSums,
        practicesSelfEfficacyBarPreNSums,
      ];
      this.practicesSelfEfficacyBarLayout = {
        title: "Practices Self Efficacy",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 6],
          tickvals: [1, 2, 3, 4, 5, 6],
          ticktext: [
            "1<br>Strongly<br>Disagree",
            "2",
            "3",
            "4",
            "5",
            "6<br>Strongly<br>Agree",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    populateContentKnowledgePlots: function () {
      const contentKnowledgeAwardPostNSum = this.scoringContentKnowledgeFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeAwardPostMeanXNSum =
        this.scoringContentKnowledgeFiltered
          .filter((e) => e.award_status === "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeAwardPostMeanScore =
        contentKnowledgeAwardPostMeanXNSum / contentKnowledgeAwardPostNSum;
      const contentKnowledgeAwardPreNSum = this.scoringContentKnowledgeFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeAwardPreMeanXNSum =
        this.scoringContentKnowledgeFiltered
          .filter((e) => e.award_status === "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeAwardPreMeanScore =
        contentKnowledgeAwardPreMeanXNSum / contentKnowledgeAwardPreNSum;

      const contentKnowledgeComparisonPostNSum =
        this.scoringContentKnowledgeFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeComparisonPostMeanXNSum =
        this.scoringContentKnowledgeFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeComparisonPostMeanScore =
        contentKnowledgeComparisonPostMeanXNSum /
        contentKnowledgeComparisonPostNSum;
      const contentKnowledgeComparisonPreNSum =
        this.scoringContentKnowledgeFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeComparisonPreMeanXNSum =
        this.scoringContentKnowledgeFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeComparisonPreMeanScore =
        contentKnowledgeComparisonPreMeanXNSum /
        contentKnowledgeComparisonPreNSum;

      const contentKnowledgeFemalePostNSum =
        this.scoringContentKnowledgeFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeFemalePostMeanXNSum =
        this.scoringContentKnowledgeFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeFemalePostMeanScore =
        contentKnowledgeFemalePostMeanXNSum / contentKnowledgeFemalePostNSum;
      const contentKnowledgeFemalePreNSum = this.scoringContentKnowledgeFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeFemalePreMeanXNSum =
        this.scoringContentKnowledgeFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeFemalePreMeanScore =
        contentKnowledgeFemalePreMeanXNSum / contentKnowledgeFemalePreNSum;

      const contentKnowledgeUnderrepPostNSum =
        this.scoringContentKnowledgeFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeUnderrepPostMeanXNSum =
        this.scoringContentKnowledgeFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeUnderrepPostMeanScore =
        contentKnowledgeUnderrepPostMeanXNSum /
        contentKnowledgeUnderrepPostNSum;
      const contentKnowledgeUnderrepPreNSum =
        this.scoringContentKnowledgeFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeUnderrepPreMeanXNSum =
        this.scoringContentKnowledgeFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const contentKnowledgeUnderrepPreMeanScore =
        contentKnowledgeUnderrepPreMeanXNSum / contentKnowledgeUnderrepPreNSum;

      const contentKnowledgeBarPostNSums = {
        x: [
          contentKnowledgeComparisonPostMeanScore,
          contentKnowledgeUnderrepPostMeanScore,
          contentKnowledgeFemalePostMeanScore,
          contentKnowledgeAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          contentKnowledgeComparisonPostMeanScore,
          contentKnowledgeUnderrepPostMeanScore,
          contentKnowledgeFemalePostMeanScore,
          contentKnowledgeAwardPostMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const contentKnowledgeBarPreNSums = {
        x: [
          contentKnowledgeComparisonPreMeanScore,
          contentKnowledgeUnderrepPreMeanScore,
          contentKnowledgeFemalePreMeanScore,
          contentKnowledgeAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          contentKnowledgeComparisonPreMeanScore,
          contentKnowledgeUnderrepPreMeanScore,
          contentKnowledgeFemalePreMeanScore,
          contentKnowledgeAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.contentKnowledgeBarCounts = [
        contentKnowledgeBarPostNSums,
        contentKnowledgeBarPreNSums,
      ];
      this.contentKnowledgeBarLayout = {
        title: "Content Knowldege",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 6],
          tickvals: [1, 2, 3, 4, 5, 6],
          ticktext: [
            "1<br>Strongly<br>Disagree",
            "2",
            "3",
            "4",
            "5",
            "6<br>Strongly<br>Agree",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    populateMentoringBestPracticesPlots: function () {
      const mentoringBestPracticesAwardPostNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter((e) => e.award_status === "award" && e.survey === "post")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesAwardPostMeanXNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter((e) => e.award_status === "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesAwardPostMeanScore =
        mentoringBestPracticesAwardPostMeanXNSum /
        mentoringBestPracticesAwardPostNSum;
      const mentoringBestPracticesAwardPreNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter((e) => e.award_status === "award" && e.survey === "pre")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesAwardPreMeanXNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter((e) => e.award_status === "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesAwardPreMeanScore =
        mentoringBestPracticesAwardPreMeanXNSum /
        mentoringBestPracticesAwardPreNSum;

      const mentoringBestPracticesComparisonPostNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesComparisonPostMeanXNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesComparisonPostMeanScore =
        mentoringBestPracticesComparisonPostMeanXNSum /
        mentoringBestPracticesComparisonPostNSum;
      const mentoringBestPracticesComparisonPreNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesComparisonPreMeanXNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesComparisonPreMeanScore =
        mentoringBestPracticesComparisonPreMeanXNSum /
        mentoringBestPracticesComparisonPreNSum;

      const mentoringBestPracticesFemalePostNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesFemalePostMeanXNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesFemalePostMeanScore =
        mentoringBestPracticesFemalePostMeanXNSum /
        mentoringBestPracticesFemalePostNSum;
      const mentoringBestPracticesFemalePreNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesFemalePreMeanXNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesFemalePreMeanScore =
        mentoringBestPracticesFemalePreMeanXNSum /
        mentoringBestPracticesFemalePreNSum;

      const mentoringBestPracticesUnderrepPostNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesUnderrepPostMeanXNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesUnderrepPostMeanScore =
        mentoringBestPracticesUnderrepPostMeanXNSum /
        mentoringBestPracticesUnderrepPostNSum;
      const mentoringBestPracticesUnderrepPreNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesUnderrepPreMeanXNSum =
        this.scoringMentoringBestPracticesFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringBestPracticesUnderrepPreMeanScore =
        mentoringBestPracticesUnderrepPreMeanXNSum /
        mentoringBestPracticesUnderrepPreNSum;

      const mentoringBestPracticesBarPostNSums = {
        x: [
          mentoringBestPracticesComparisonPostMeanScore,
          mentoringBestPracticesUnderrepPostMeanScore,
          mentoringBestPracticesFemalePostMeanScore,
          mentoringBestPracticesAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          mentoringBestPracticesComparisonPostMeanScore,
          mentoringBestPracticesUnderrepPostMeanScore,
          mentoringBestPracticesFemalePostMeanScore,
          mentoringBestPracticesAwardPostMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const mentoringBestPracticesBarPreNSums = {
        x: [
          mentoringBestPracticesComparisonPreMeanScore,
          mentoringBestPracticesUnderrepPreMeanScore,
          mentoringBestPracticesFemalePreMeanScore,
          mentoringBestPracticesAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          mentoringBestPracticesComparisonPreMeanScore,
          mentoringBestPracticesUnderrepPreMeanScore,
          mentoringBestPracticesFemalePreMeanScore,
          mentoringBestPracticesAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.mentoringBestPracticesBarCounts = [
        mentoringBestPracticesBarPostNSums,
        mentoringBestPracticesBarPreNSums,
      ];
      this.mentoringBestPracticesBarLayout = {
        title: "Mentoring Best Practices",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 6],
          tickvals: [1, 2, 3, 4, 5, 6],
          ticktext: [
            "1<br>Strongly<br>Disagree",
            "2",
            "3",
            "4",
            "5",
            "6<br>Strongly<br>Agree",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    populateMentoringTimePlots: function () {
      const mentoringTimeAwardPostNSum = this.scoringMentoringTimeFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeAwardPostMeanXNSum = this.scoringMentoringTimeFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeAwardPostMeanScore =
        mentoringTimeAwardPostMeanXNSum / mentoringTimeAwardPostNSum;
      const mentoringTimeAwardPreNSum = this.scoringMentoringTimeFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeAwardPreMeanXNSum = this.scoringMentoringTimeFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeAwardPreMeanScore =
        mentoringTimeAwardPreMeanXNSum / mentoringTimeAwardPreNSum;

      const mentoringTimeComparisonPostNSum = this.scoringMentoringTimeFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeComparisonPostMeanXNSum =
        this.scoringMentoringTimeFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "post")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeComparisonPostMeanScore =
        mentoringTimeComparisonPostMeanXNSum / mentoringTimeComparisonPostNSum;
      const mentoringTimeComparisonPreNSum = this.scoringMentoringTimeFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeComparisonPreMeanXNSum =
        this.scoringMentoringTimeFiltered
          .filter((e) => e.award_status !== "award" && e.survey === "pre")
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeComparisonPreMeanScore =
        mentoringTimeComparisonPreMeanXNSum / mentoringTimeComparisonPreNSum;

      const mentoringTimeFemalePostNSum = this.scoringMentoringTimeFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeFemalePostMeanXNSum = this.scoringMentoringTimeFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeFemalePostMeanScore =
        mentoringTimeFemalePostMeanXNSum / mentoringTimeFemalePostNSum;
      const mentoringTimeFemalePreNSum = this.scoringMentoringTimeFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeFemalePreMeanXNSum = this.scoringMentoringTimeFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeFemalePreMeanScore =
        mentoringTimeFemalePreMeanXNSum / mentoringTimeFemalePreNSum;

      const mentoringTimeUnderrepPostNSum = this.scoringMentoringTimeFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeUnderrepPostMeanXNSum =
        this.scoringMentoringTimeFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "post"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeUnderrepPostMeanScore =
        mentoringTimeUnderrepPostMeanXNSum / mentoringTimeUnderrepPostNSum;
      const mentoringTimeUnderrepPreNSum = this.scoringMentoringTimeFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeUnderrepPreMeanXNSum =
        this.scoringMentoringTimeFiltered
          .filter(
            (e) =>
              e.award_status === "award" &&
              e.female === "Yes" &&
              e.survey === "pre"
          )
          .map((e) => e.mean_response * e.n_responses)
          .reduce((pv, cv) => pv + cv, 0);
      const mentoringTimeUnderrepPreMeanScore =
        mentoringTimeUnderrepPreMeanXNSum / mentoringTimeUnderrepPreNSum;

      const mentoringTimeBarPostNSums = {
        x: [
          mentoringTimeComparisonPostMeanScore,
          mentoringTimeUnderrepPostMeanScore,
          mentoringTimeFemalePostMeanScore,
          mentoringTimeAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          mentoringTimeComparisonPostMeanScore,
          mentoringTimeUnderrepPostMeanScore,
          mentoringTimeFemalePostMeanScore,
          mentoringTimeAwardPostMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const mentoringTimeBarPreNSums = {
        x: [
          mentoringTimeComparisonPreMeanScore,
          mentoringTimeUnderrepPreMeanScore,
          mentoringTimeFemalePreMeanScore,
          mentoringTimeAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          mentoringTimeComparisonPreMeanScore,
          mentoringTimeUnderrepPreMeanScore,
          mentoringTimeFemalePreMeanScore,
          mentoringTimeAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.mentoringTimeBarCounts = [
        mentoringTimeBarPostNSums,
        mentoringTimeBarPreNSums,
      ];
      this.mentoringTimeBarLayout = {
        title: "Mentoring Time",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 6],
          tickvals: [1, 2, 3, 4, 5, 6],
          ticktext: [
            "1<br>No<br>Contact",
            "2",
            "3",
            "4",
            "5",
            "6<br>Way too much<br>contact",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    populateEngagementPlots: function () {
      const engagementAwardPostNSum = this.scoringEngagementFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementAwardPostMeanXNSum = this.scoringEngagementFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementAwardPostMeanScore =
        engagementAwardPostMeanXNSum / engagementAwardPostNSum;
      const engagementAwardPreNSum = this.scoringEngagementFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementAwardPreMeanXNSum = this.scoringEngagementFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementAwardPreMeanScore =
        engagementAwardPreMeanXNSum / engagementAwardPreNSum;

      const engagementComparisonPostNSum = this.scoringEngagementFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementComparisonPostMeanXNSum = this.scoringEngagementFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementComparisonPostMeanScore =
        engagementComparisonPostMeanXNSum / engagementComparisonPostNSum;
      const engagementComparisonPreNSum = this.scoringEngagementFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementComparisonPreMeanXNSum = this.scoringEngagementFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementComparisonPreMeanScore =
        engagementComparisonPreMeanXNSum / engagementComparisonPreNSum;

      const engagementFemalePostNSum = this.scoringEngagementFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementFemalePostMeanXNSum = this.scoringEngagementFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementFemalePostMeanScore =
        engagementFemalePostMeanXNSum / engagementFemalePostNSum;
      const engagementFemalePreNSum = this.scoringEngagementFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementFemalePreMeanXNSum = this.scoringEngagementFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementFemalePreMeanScore =
        engagementFemalePreMeanXNSum / engagementFemalePreNSum;

      const engagementUnderrepPostNSum = this.scoringEngagementFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementUnderrepPostMeanXNSum = this.scoringEngagementFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementUnderrepPostMeanScore =
        engagementUnderrepPostMeanXNSum / engagementUnderrepPostNSum;
      const engagementUnderrepPreNSum = this.scoringEngagementFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementUnderrepPreMeanXNSum = this.scoringEngagementFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const engagementUnderrepPreMeanScore =
        engagementUnderrepPreMeanXNSum / engagementUnderrepPreNSum;

      const engagementBarPostNSums = {
        x: [
          engagementComparisonPostMeanScore,
          engagementUnderrepPostMeanScore,
          engagementFemalePostMeanScore,
          engagementAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          engagementComparisonPostMeanScore,
          engagementUnderrepPostMeanScore,
          engagementFemalePostMeanScore,
          engagementAwardPostMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const engagementBarPreNSums = {
        x: [
          engagementComparisonPreMeanScore,
          engagementUnderrepPreMeanScore,
          engagementFemalePreMeanScore,
          engagementAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          engagementComparisonPreMeanScore,
          engagementUnderrepPreMeanScore,
          engagementFemalePreMeanScore,
          engagementAwardPreMeanScore
        ).map((v) => v.toFixed(1)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.engagementBarCounts = [
        engagementBarPostNSums,
        engagementBarPreNSums,
      ];
      this.engagementBarLayout = {
        title: "Engagement",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [1, 6],
          tickvals: [1, 2, 3, 4, 5, 6],
          ticktext: [
            "1<br>Strongly<br>Disagree",
            "2",
            "3",
            "4",
            "5",
            "6<br>Strongly<br>Agree",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    populateAutonomyPlots: function () {
      const autonomyAwardPostNSum = this.scoringAutonomyFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyAwardPostMeanXNSum = this.scoringAutonomyFiltered
        .filter((e) => e.award_status === "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyAwardPostMeanScore =
        autonomyAwardPostMeanXNSum / autonomyAwardPostNSum;
      const autonomyAwardPreNSum = this.scoringAutonomyFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyAwardPreMeanXNSum = this.scoringAutonomyFiltered
        .filter((e) => e.award_status === "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyAwardPreMeanScore =
        autonomyAwardPreMeanXNSum / autonomyAwardPreNSum;

      const autonomyComparisonPostNSum = this.scoringAutonomyFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyComparisonPostMeanXNSum = this.scoringAutonomyFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "post")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyComparisonPostMeanScore =
        autonomyComparisonPostMeanXNSum / autonomyComparisonPostNSum;
      const autonomyComparisonPreNSum = this.scoringAutonomyFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyComparisonPreMeanXNSum = this.scoringAutonomyFiltered
        .filter((e) => e.award_status !== "award" && e.survey === "pre")
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyComparisonPreMeanScore =
        autonomyComparisonPreMeanXNSum / autonomyComparisonPreNSum;

      const autonomyFemalePostNSum = this.scoringAutonomyFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyFemalePostMeanXNSum = this.scoringAutonomyFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyFemalePostMeanScore =
        autonomyFemalePostMeanXNSum / autonomyFemalePostNSum;
      const autonomyFemalePreNSum = this.scoringAutonomyFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyFemalePreMeanXNSum = this.scoringAutonomyFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyFemalePreMeanScore =
        autonomyFemalePreMeanXNSum / autonomyFemalePreNSum;

      const autonomyUnderrepPostNSum = this.scoringAutonomyFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyUnderrepPostMeanXNSum = this.scoringAutonomyFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "post"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyUnderrepPostMeanScore =
        autonomyUnderrepPostMeanXNSum / autonomyUnderrepPostNSum;
      const autonomyUnderrepPreNSum = this.scoringAutonomyFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyUnderrepPreMeanXNSum = this.scoringAutonomyFiltered
        .filter(
          (e) =>
            e.award_status === "award" &&
            e.female === "Yes" &&
            e.survey === "pre"
        )
        .map((e) => e.mean_response * e.n_responses)
        .reduce((pv, cv) => pv + cv, 0);
      const autonomyUnderrepPreMeanScore =
        autonomyUnderrepPreMeanXNSum / autonomyUnderrepPreNSum;

      const autonomyBarPostNSums = {
        x: [
          autonomyComparisonPostMeanScore,
          autonomyUnderrepPostMeanScore,
          autonomyFemalePostMeanScore,
          autonomyAwardPostMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Post",
        text: Array(
          autonomyComparisonPostMeanScore,
          autonomyUnderrepPostMeanScore,
          autonomyFemalePostMeanScore,
          autonomyAwardPostMeanScore
        ).map((v) => v.toFixed(2)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#CB4D42",
        },
      };
      const autonomyBarPreNSums = {
        x: [
          autonomyComparisonPreMeanScore,
          autonomyUnderrepPreMeanScore,
          autonomyFemalePreMeanScore,
          autonomyAwardPreMeanScore,
        ],
        y: [
          "Comparison Students",
          "Non-white, non-Asian<br>Space Grant Students",
          "Female Space Grant<br>Students",
          "Space Grant Students",
        ],
        type: "bar",
        orientation: "h",
        name: "Pre",
        text: Array(
          autonomyComparisonPreMeanScore,
          autonomyUnderrepPreMeanScore,
          autonomyFemalePreMeanScore,
          autonomyAwardPreMeanScore
        ).map((v) => v.toFixed(2)),
        textposition: "auto",
        hoverinfo: "none",
        marker: {
          color: "#00989D",
        },
      };
      this.autonomyBarCounts = [autonomyBarPostNSums, autonomyBarPreNSums];
      this.autonomyBarLayout = {
        title: "Autonomy",
        barmode: "group",
        legend: {
          traceorder: "reversed",
        },
        xaxis: {
          range: [0, 1],
          tickvals: [0, 0.25, 0.5, 0.75, 1],
          ticktext: [
            "0<br>Decided<br>by others",
            "0.25",
            "0.5",
            "0.75",
            "1<br>Student<br>Led",
          ],
        },
        yaxis: {
          automargin: true,
          ticklen: 5,
        },
        margin: {
          t: 27,
        },
      };
    },
    updateLocalData: function () {
      localforage
        .setItem("refState", this.refState)
        .catch((e) =>
          console.log(`error setting refState to localforage: ${e}`)
        );
      localforage
        .setItem("refStateRegion", this.refStateRegion)
        .catch((e) =>
          console.log(`error setting refStateRegion to localforage: ${e}`)
        );
      localforage
        .setItem("scoringStemExperience", this.scoringStemExperience)
        .catch((e) =>
          console.log(
            `error setting scoringStemExperience to localforage: ${e}`
          )
        );
      localforage
        .setItem("scoringStemIdentity", this.scoringStemIdentity)
        .catch((e) =>
          console.log(`error setting scoringStemIdentity to localforage: ${e}`)
        );
      // localforage
      //   .setItem(
      //     "scoringStemProfessionalIdentity",
      //     this.scoringStemProfessionalIdentity
      //   )
      //   .catch((e) =>
      //     console.log(
      //       `error setting scoringStemProfessionalIdentity to localforage: ${e}`
      //     )
      //   );
      localforage
        .setItem("scoringStemExposure", this.scoringStemExposure)
        .catch((e) =>
          console.log(`error setting scoringStemExposure to localforage: ${e}`)
        );
      localforage
        .setItem("scoringBelonging", this.scoringBelonging)
        .catch((e) =>
          console.log(`error setting scoringBelonging to localforage: ${e}`)
        );
      localforage
        .setItem("scoringAspirations", this.scoringAspirations)
        .catch((e) =>
          console.log(`error setting scoringAspirations to localforage: ${e}`)
        );
      localforage
        .setItem(
          "scoringAcademicSelfEfficacy",
          this.scoringAcademicSelfEfficacy
        )
        .catch((e) =>
          console.log(
            `error setting scoringAcademicSelfEfficacy to localforage: ${e}`
          )
        );
      localforage
        .setItem(
          "scoringPracticesSelfEfficacy",
          this.scoringPracticesSelfEfficacy
        )
        .catch((e) =>
          console.log(
            `error setting scoringPracticesSelfEfficacy to localforage: ${e}`
          )
        );
      localforage
        .setItem("scoringContentKnowledge", this.scoringContentKnowledge)
        .catch((e) =>
          console.log(
            `error setting scoringContentKnowledge to localforage: ${e}`
          )
        );
      localforage
        .setItem(
          "scoringMentoringBestPractices",
          this.scoringMentoringBestPractices
        )
        .catch((e) =>
          console.log(
            `error setting scoringMentoringBestPractices to localforage: ${e}`
          )
        );
      localforage
        .setItem("scoringMentoringTime", this.scoringMentoringTime)
        .catch((e) =>
          console.log(`error setting scoringMentoringTime to localforage: ${e}`)
        );
      localforage
        .setItem("scoringEngagement", this.scoringEngagement)
        .catch((e) =>
          console.log(`error setting scoringEngagement to localforage: ${e}`)
        );
      localforage
        .setItem("scoringAutonomy", this.scoringAutonomy)
        .catch((e) =>
          console.log(`error setting scoringAutonomy to localforage: ${e}`)
        );
    },
    stateSort: function (a, b) {
      if (a.state > b.state) {
        return -1;
      }
      if (a.state < b.state) {
        return 1;
      }
      return 0;
    },
    noSort: function () {
      return 0;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    noFilter: function () {
      return 1;
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
  },
};
</script>

<style scoped>
.fixed {
  position: fixed;
  top: 49px;
  left: 256px;
  z-index: 5;
  width: 100%;
  background-color: white;
}

.post-fixed {
  padding-top: 105px;
}

.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid black;
  clear: both;
}

.reddish {
  background-color: "#CB4D42" !important;
}

.blueish {
  background-color: "#00989D" !important;
}
</style>
