<template>
  <ChecklistStudent />
</template>

<script>
import ChecklistStudent from "../components/ChecklistStudent.vue";

export default {
  name: "ChecklistStudentView",

  components: {
    ChecklistStudent,
  },
};
</script>
