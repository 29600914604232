<template>
  <v-app id="app">
    <v-app-bar app clipped-left dense color="primary" dark>
      <v-app-bar-title> NASA STEM Survey Data </v-app-bar-title
      ><v-spacer></v-spacer
      ><v-app-bar-title>
        <v-btn icon to="/">
          <v-icon>mdi-home-circle</v-icon>
        </v-btn>
        <v-btn icon to="/single-survey">
          <v-icon>mdi-clipboard-check</v-icon>
        </v-btn>
        <v-btn icon to="/student-list">
          <v-icon>mdi-account-multiple</v-icon>
        </v-btn>
        <v-btn icon to="/checklist-award-pre-survey">
          <v-icon>mdi-calendar-clock</v-icon>
        </v-btn>
        <v-btn icon to="/download-tables">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn icon to="/help">
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-app-bar-title>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <v-footer
      color="primary"
      app
      fixed
      dark
      v-if="$store.getters.username && $store.getters.username.length"
    >
      <p>Logged in as {{ $store.getters.username }}</p>
      <v-spacer />
      <v-btn color="success" @click="$store.dispatch('logout')">Logout</v-btn>
    </v-footer>
    <v-footer v-else color="warning" app fixed dark>
      <p>Not logged in</p>
      <v-spacer />
      <v-btn
        color="secondary"
        @click="
          $router.push({
            name: 'login',
            query: { redirect: $router.currentRoute.path },
          })
        "
        >Login</v-btn
      >
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
html {
  overflow-y: auto !important;
}
.v-application--wrap {
  min-height: 100% !important;
}
#app {
  font-family: source_sans_pro, Helvetica, Arial, sans-serif;
  margin-top: 20px;
}
</style>
