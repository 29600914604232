<template>
  <div v-if="exists">
    <v-btn icon v-for="i in count" :key="i" small
      ><v-icon>mdi-check-circle</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  name: "CheckExists",
  props: {
    check: String,
  },
  computed: {
    exists() {
      return this.check && this.check.length;
    },
    count() {
      if (this.exists) {
        return (this.check.match(/,/g) || []).length + 1;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style></style>
