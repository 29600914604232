<template>
  <v-container>
    <!-- SNACK -->
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="2000"
      v-model="uploadSuccess"
    >
      <div class="text-body-1">
        uploaded to server ({{ rowsInserted }} rows)
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="uploadSuccess = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-container>
      <SurveyNavDrawer />
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
      />
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="matchStudentSurvey"
        :items-per-page="25"
        :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
        :loading="!matchStudentSurvey || !matchStudentSurvey.length"
        dense
      >
        <template v-slot:top>
          <v-dialog v-model="dialog" dense max-width="50%">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedStudent.survey_name"
                        label="Name"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.survey_email"
                        label="Email"
                      ></v-text-field>
                      <v-autocomplete
                        v-model="editedStudent.student_name"
                        label="Matching Name"
                        :items="student"
                        item-text="name"
                        item-value="name"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="editedStudent.student_email"
                        label="Matching Email"
                        :items="student"
                        item-text="email"
                        item-value="email"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.date_modified`]="{ item }">
          {{ formatDateNoBreak(item.date_modified) }}
        </template>
        <template v-slot:[`item.respondent_id`]="{ item }">
          <router-link :to="'/single-survey/' + item.respondent_id">{{
            item.respondent_id
          }}</router-link>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editStudent(item)"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import SurveyNavDrawer from "../components/SurveyNavDrawer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "SurveyUnmatched",
  components: {
    Loading,
    SurveyNavDrawer,
  },
  data: () => ({
    version: "2022-12-23 07:54:38",
    isLoading: false,
    valid: true,
    matchStudentSurvey: [],
    student: [],
    headers: [
      { text: "ID", value: "respondent_id" },
      { text: "Survey", value: "survey_stub" },
      { text: "Date", value: "date_modified" },
      { text: "Survey Status", value: "survey_status" },
      { text: "Student Status", value: "student_status" },
      { text: "Survey Name", value: "survey_name" },
      { text: "Survey Email", value: "survey_email" },
      { text: "Student Name", value: "student_name" },
      { text: "Student Email", value: "student_email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dialog: false,
    editedStudent: {
      survey_name: "",
      survey_email: "",
      student_name: "",
      student_email: "",
    },
    defaultStudent: {
      survey_name: "",
      survey_email: "",
      student_name: "",
      student_email: "",
    },
    editedIndex: -1,
    uploadSuccess: false,
    rowsInserted: 0,
  }),
  computed: {},
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      await Promise.all([
        this.getApiData(
          "/match_student_survey/",
          "matchStudentSurvey",
          this.unmatchFilter,
          this.surveyNameSort
        ),
        this.getApiData(
          "/student/",
          "student",
          this.supersededFilter,
          this.surveyNameSort
        ),
      ]);
    }
  },
  methods: {
    sequenceSort: function (a, b) {
      return a.sequence - b.sequence;
    },
    surveyNameSort: function (a, b) {
      return a.survey_name > b.survey_name;
    },
    surveyIDSort: function (a, b) {
      return a.survey_id - b.survey_id;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    unmatchFilter: function (a) {
      return (
        a.survey_stub !== "mentor" &&
        a.response_status === "completed" &&
        a.survey_status === "valid" &&
        // WARNING: welcome only
        (a.survey_id === 511024186 ||
          (a.survey_id === 510188122 &&
            a.date_modified < "2023-02-25T00:00:00-09:00")) &&
        (!a.student_name || a.student_name !== a.survey_name)
      );
    },
    editStudent: function (s) {
      this.editedIndex = this.matchStudentSurvey.indexOf(s);
      this.editedStudent = Object.assign({}, s);
      this.dialog = true;
    },
    close: function () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedStudent = Object.assign({}, this.defaultStudent);
        this.editedIndex = -1;
      });
    },
    save: async function () {
      if (
        this.editedStudent.student_name &&
        this.editedStudent.student_name.length &&
        (!this.editedStudent.student_email ||
          !this.editedStudent.student_email.length)
      ) {
        let s = this.student.filter(
          (s) => s.name === this.editedStudent.student_name
        )[0];
        this.editedStudent.student_email = s.email;
      }
      if (
        this.editedStudent.student_email &&
        this.editedStudent.student_email.length &&
        (!this.editedStudent.student_name ||
          !this.editedStudent.student_name.length)
      ) {
        let s = this.student.filter(
          (s) => s.email === this.editedStudent.student_email
        )[0];
        this.editedStudent.student_name = s.name;
      }
      if (this.editedIndex > -1) {
        Object.assign(
          this.matchStudentSurvey[this.editedIndex],
          this.editedStudent
        );
      } else {
        this.matchStudentSurvey.push(this.editedStudent);
      }
      let dte = new Date();
      let payload = [
        {
          name_variant: this.editedStudent.survey_name,
          name: this.editedStudent.student_name,
          date_added: `${dte.getFullYear()}-${(dte.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${dte.getDate().toString().padStart(2, "0")}`,
          notes: null,
        },
      ];
      let rowsInserted = 0;
      await axios
        .post("name_variant/keys/u/", payload, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status === 200) {
            for (let i in response.data.message) {
              if (
                response.data.status[i] != "error" &&
                response.data.message[i].search("inserted") > -1
              ) {
                rowsInserted = rowsInserted + 1;
              } else {
                console.log("error submitting: " + response.data.message[i]);
              }
            }
          } else {
            console.log("post fail, code = " + response.status);
          }
        })
        .catch((err) => console.log("error posting: ", err));
      if (rowsInserted > 0) {
        this.rowsInserted = rowsInserted;
        this.uploadSuccess = true;
        await this.getApiData(
          "/match_student_survey/",
          "matchStudentSurvey",
          this.unmatchFilter,
          this.surveyNameSort
        );
      }
      this.close();
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
    // Assumes UTC because Postgres dates (no times) come in with
    // a "T00:00:00Z" timestamp, so they need to be interpreted as UTC
    formatDate: function (d) {
      let ts = new Date(d);
      return `${ts.getUTCFullYear()}-${(ts.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ts.getUTCDate().toString().padStart(2, "0")}`;
    },
    // Assumes UTC because Postgres dates (no times) come in with
    // a "T00:00:00Z" timestamp, so they need to be interpreted as UTC
    formatDateNoBreak: function (d) {
      if (d) {
        let ts = new Date(d);
        return `${ts.getUTCFullYear()}‑${(ts.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}‑${ts.getUTCDate().toString().padStart(2, "0")}`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style></style>
