<template>
  <SurveyUnmatched />
</template>

<script>
import SurveyUnmatched from "../components/SurveyUnmatched.vue";

export default {
  name: "SurveyUnmatchedView",

  components: {
    SurveyUnmatched,
  },
};
</script>
