<template>
  <DashboardSurveyResponse />
</template>

<script>
import DashboardSurveyResponse from "../components/DashboardSurveyResponse.vue";

export default {
  name: "DashboardSurveyResponseView",

  components: {
    DashboardSurveyResponse,
  },
};
</script>
