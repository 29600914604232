<template>
  <v-container>
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true" />
    <v-container
      v-if="
        $store.getters.username && $store.getters.username.length && !isLoading
      "
    >
      <v-row>
        <h2>Download Raw Tables</h2>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="10">
          <p>
            Click on the buttons below to download CSV versions of the raw
            database tables. These tables include:
          </p>
          <ul>
            <li><code>student</code>: Full list of all students.</li>
            <li>
              <code>name_variant</code>: Contains mapping between a student's
              name in the student list (the <code>name</code> column) and the
              names used in survey responses (<code>name_variant</code>).
            </li>
            <li><code>survey_responste</code>: All survey responses.</li>
          </ul>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-btn
          class="ml-2"
          color="success"
          :loading="studentLoading"
          :disabled="studentLoading"
          @click="downloadStudent"
          >Download Student List</v-btn
        >
        <v-btn
          class="ml-2"
          color="success"
          :loading="nameVariantLoading"
          :disabled="nameVariantLoading"
          @click="downloadNameVariant"
          >Download Name Variant Table</v-btn
        >
        <v-btn
          class="ml-2"
          color="success"
          :loading="surveyResponseLoading"
          :disabled="surveyResponseLoading"
          @click="downloadSurveyResponse"
          >Download Survey Response Tables</v-btn
        >
      </v-row>
      <v-row class="mt-5">
        <h2>Download Results</h2>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="10">
          <p>Results tables:</p>
          <ul>
            <li>
              <code>pre_survey_export</code>: Pivoted table of pre-survey
              results.
            </li>
            <li>
              <code>pre_survey_export_questions</code>: The questions, linked to
              the columns in the <code>pre_survey_export</code> view above.
            </li>
            <li>
              <code>post_survey_export</code>: Pivoted table of post-survey
              results.
            </li>
            <li>
              <code>post_survey_export_questions</code>: The questions, linked
              to the columns in the <code>post_survey_export</code> view above.
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-btn
          class="ml-2"
          color="success"
          :loading="preSurveyExportLoading"
          :disabled="preSurveyExportLoading"
          @click="downloadPreSurveyExport"
          >Download Pre-survey Results</v-btn
        >
        <v-btn
          class="ml-2"
          color="success"
          :loading="preSurveyExportQuestionsLoading"
          :disabled="preSurveyExportQuestionsLoading"
          @click="downloadPreSurveyExportQuestions"
          >Download Pre-survey Questions</v-btn
        >
      </v-row>
      <v-row class="mb-2">
        <v-btn
          class="ml-2"
          color="success"
          :loading="postSurveyExportLoading"
          :disabled="postSurveyExportLoading"
          @click="downloadPostSurveyExport"
          >Download Post-survey Results</v-btn
        >
        <v-btn
          class="ml-2"
          color="success"
          :loading="postSurveyExportQuestionsLoading"
          :disabled="postSurveyExportQuestionsLoading"
          @click="downloadPostSurveyExportQuestions"
          >Download Post-survey Questions</v-btn
        >
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "DownloadTables",
  components: {
    Loading,
  },
  data: () => ({
    isLoading: false,
    studentLoading: false,
    nameVariantLoading: false,
    surveyResponseLoading: false,
    preSurveyExportLoading: false,
    preSurveyExportQuestionsLoading: false,
    postSurveyExportLoading: false,
    postSurveyExportQuestionsLoading: false,
    surveyResponse: null,
    student: null,
    nameVariant: null,
    preSurveyExport: null,
    preSurveyExportQuestions: null,
    postSurveyExport: null,
    postSurveyExportQuestions: null,
  }),
  computed: {},
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
  },
  methods: {
    downloadStudent: async function () {
      this.studentLoading = true;
      if (this.$store.getters.username && this.$store.getters.username.length) {
        if (!this.student || this.student.length === 0) {
          await this.getApiData(
            "/student/",
            "student",
            this.noFilter,
            this.noSort
          );
        }
        this.student.forEach((obj) => {
          obj.student_id = obj.serial;
          delete obj.serial;
        });
        let csvData = this.$papa.unparse(this.student);
        const ts = new Date();
        const dte = this.formatDate(ts);
        this.$papa.download(csvData, `student_list_${dte}`);
      }
      this.studentLoading = false;
    },
    downloadNameVariant: async function () {
      this.nameVariantLoading = true;
      if (this.$store.getters.username && this.$store.getters.username.length) {
        if (!this.student || this.student.length === 0) {
          await this.getApiData(
            "/name_variant/",
            "nameVariant",
            this.noFilter,
            this.noSort
          );
        }
        let csvData = this.$papa.unparse(this.nameVariant);
        const ts = new Date();
        const dte = this.formatDate(ts);
        this.$papa.download(csvData, `name_variant_${dte}`);
      }
      this.nameVariantLoading = false;
    },
    downloadSurveyResponse: async function () {
      this.surveyResponseLoading = true;
      if (this.$store.getters.username && this.$store.getters.username.length) {
        if (!this.student || this.student.length === 0) {
          await this.getApiData(
            "/survey_response/",
            "surveyResponse",
            this.noFilter,
            this.noSort
          );
        }
        let csvData = this.$papa.unparse(this.surveyResponse);
        const ts = new Date();
        const dte = this.formatDate(ts);
        this.$papa.download(csvData, `survey_response_${dte}`);
      }
      this.surveyResponseLoading = false;
    },
    downloadPreSurveyExport: async function () {
      this.preSurveyExportLoading = true;
      if (this.$store.getters.username && this.$store.getters.username.length) {
        if (!this.student || this.student.length === 0) {
          await this.getApiData(
            "/pre_survey_export/",
            "preSurveyExport",
            this.noFilter,
            this.noSort
          );
        }
        let csvData = this.$papa.unparse(this.preSurveyExport);
        const ts = new Date();
        const dte = this.formatDate(ts);
        this.$papa.download(csvData, `pre_survey_export_${dte}`);
      }
      this.preSurveyExportLoading = false;
    },
    downloadPreSurveyExportQuestions: async function () {
      this.preSurveyExportQuestionsLoading = true;
      if (this.$store.getters.username && this.$store.getters.username.length) {
        if (!this.student || this.student.length === 0) {
          await this.getApiData(
            "/pre_survey_export_questions/",
            "preSurveyExportQuestions",
            this.noFilter,
            this.noSort
          );
        }
        let csvData = this.$papa.unparse(this.preSurveyExportQuestions);
        const ts = new Date();
        const dte = this.formatDate(ts);
        this.$papa.download(csvData, `pre_survey_export_questions_${dte}`);
      }
      this.preSurveyExportQuestionsLoading = false;
    },
    downloadPostSurveyExport: async function () {
      this.postSurveyExportLoading = true;
      if (this.$store.getters.username && this.$store.getters.username.length) {
        if (!this.student || this.student.length === 0) {
          await this.getApiData(
            "/post_survey_export/",
            "postSurveyExport",
            this.noFilter,
            this.noSort
          );
        }
        let csvData = this.$papa.unparse(this.postSurveyExport);
        const ts = new Date();
        const dte = this.formatDate(ts);
        this.$papa.download(csvData, `post_survey_export_${dte}`);
      }
      this.postSurveyExportLoading = false;
    },
    downloadPostSurveyExportQuestions: async function () {
      this.postSurveyExportQuestionsLoading = true;
      if (this.$store.getters.username && this.$store.getters.username.length) {
        if (!this.student || this.student.length === 0) {
          await this.getApiData(
            "/post_survey_export_questions/",
            "postSurveyExportQuestions",
            this.noFilter,
            this.noSort
          );
        }
        let csvData = this.$papa.unparse(this.postSurveyExportQuestions);
        const ts = new Date();
        const dte = this.formatDate(ts);
        this.$papa.download(csvData, `post_survey_export_questions_${dte}`);
      }
      this.postSurveyExportQuestionsLoading = false;
    },
    stateSort: function (a, b) {
      if (a.state > b.state) {
        return -1;
      }
      if (a.state < b.state) {
        return 1;
      }
      return 0;
    },
    noSort: function () {
      return 0;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    noFilter: function () {
      return 1;
    },
    renameSerial: function (obj) {
      obj.student_id = obj.serial;
      delete obj.serial;

      return obj;
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
    // Assumes UTC because Postgres dates (no times) come in with
    // a "T00:00:00Z" timestamp, so they need to be interpreted as UTC
    formatDate: function (d) {
      let ts = new Date(d);
      return `${ts.getUTCFullYear()}-${(ts.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ts.getUTCDate().toString().padStart(2, "0")}`;
    },
  },
};
</script>

<style></style>
