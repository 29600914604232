<template>
  <ChecklistAwardOngoingSurvey />
</template>

<script>
import ChecklistAwardOngoingSurvey from "../components/ChecklistAwardOngoingSurvey.vue";

export default {
  name: "ChecklistAwardOngoingSurveyView",

  components: {
    ChecklistAwardOngoingSurvey,
  },
};
</script>
