<template>
  <v-container>
    <div style="margin-left: -12px">Mentors</div>
    <v-row v-for="(m, index) in mentors" :key="m">
      <v-autocomplete
        v-model="initialMentors[index]"
        :items="mentor"
        item-text="mentor_name"
        item-value="mentor_name"
      >
      </v-autocomplete>
      <v-btn class="ml-2 mt-2" color="warning" @click="delMentor(index)"
        >Delete</v-btn
      >
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn color="success" @click="addMentor">Add Mentor</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MentorArraySelect",
  props: {
    mentor: Array,
    mentors: Array,
  },
  computed: {
    initialMentors: {
      get() {
        return this.mentors;
      },
      set(val) {
        this.$emit("update", val);
      },
    },
  },
  methods: {
    addMentor: function () {
      this.initialMentors.push("");
    },
    delMentor: function (i) {
      this.initialMentors.splice(i, 1);
    },
  },
};
</script>

<style scoped></style>
