<template>
  <StudentMentor />
</template>

<script>
import StudentMentor from "../components/StudentMentor.vue";

export default {
  name: "StudentMentorView",

  components: {
    StudentMentor,
  },
};
</script>
