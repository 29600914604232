<template>
  <DownloadTables />
</template>

<script>
import DownloadTables from "../components/DownloadTables.vue";

export default {
  name: "DownloadTablesView",

  components: {
    DownloadTables,
  },
};
</script>
