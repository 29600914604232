<template>
  <div class="box" :style="style"></div>
</template>

<script>
export default {
  name: "ColoredSquare",
  props: ["color"],
  components: {},
  data: () => ({}),
  computed: {
    style() {
      return `background-color: ${this.color}`;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.box {
  float: left;
  height: 20px;
  width: 20px;
  margin-bottom: 15px;
  border: 1px solid black;
  clear: both;
}
</style>
