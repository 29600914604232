<template>
  <StudentUpload />
</template>

<script>
import StudentUpload from "../components/StudentUpload.vue";

export default {
  name: "StudentUploadView",

  components: {
    StudentUpload,
  },
};
</script>
