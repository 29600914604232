<template>
  <v-container>
    <ChecklistNavDrawer />
    <!-- SNACK -->
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="2000"
      v-model="uploadSuccess"
    >
      <div class="text-body-1">
        uploaded to server ({{ rowsInserted }} rows)
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="uploadSuccess = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-container>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
      />
    </v-container>
    <v-container>
      <v-row class="mb-2">
        <v-col>
          <v-switch
            v-model="includeOldSurveys"
            inset
            :label="`Include older surveys`"
            @change="changeOldSurvey"
          ></v-switch>
        </v-col>
        <v-col>
          <v-switch
            v-model="showFullChecklist"
            inset
            :label="`Show completed tasks`"
            @change="changeOldSurvey"
            >Show all students</v-switch
          >
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="checklistStudent"
        :items-per-page="25"
        :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
        :loading="!checklistStudent || !checklistStudent.length"
        dense
      >
        <template v-slot:header>
          <thead>
            <tr class="center">
              <th colspan="3">Student</th>
              <th colspan="2">Pre-Survey</th>
              <th colspan="2">Ongoing Survey</th>
              <!-- <th colspan="3">Interview</th> -->
              <th>&nbsp;</th>
            </tr>
          </thead>
        </template>
        <template v-slot:[`item.student_date_added`]="{ item }">
          {{ formatDateNoBreak(item.student_date_added) }}
        </template>
        <template v-slot:[`item.pre_complete`]="{ item }">
          <!-- <CheckExists :check="item.pre_complete"></CheckExists> -->
          <custom-date-check :check="item.pre_complete"></custom-date-check>
        </template>
        <template v-slot:[`item.pre_reward`]="{ item }"
          ><custom-date-check
            :check="item.pre_reward"
            @check-clicked="
              item.pre_reward = $event.new;
              previousPreReward = $event.old;
            "
          ></custom-date-check
        ></template>
        <template v-slot:[`item.ongoing_complete`]="{ item }"
          ><CheckExistsMultiple
            :check="item.ongoing_complete"
          ></CheckExistsMultiple
        ></template>
        <template v-slot:[`item.ongoing_reward`]="{ item }"
          ><CheckExistsMultiple
            :check="item.ongoing_reward"
          ></CheckExistsMultiple
        ></template>
        <!-- <template v-slot:[`item.interview_invite`]="{ item }" -->
        <!--   ><CheckExists :check="item.interview_invite"></CheckExists -->
        <!-- ></template> -->
        <!-- <template v-slot:[`item.interview_schedule`]="{ item }" -->
        <!--   ><CheckExists :check="item.interview_schedule"></CheckExists -->
        <!-- ></template> -->
        <!-- <template v-slot:[`item.interview_complete`]="{ item }" -->
        <!--   ><CheckExists :check="item.interview_complete"></CheckExists -->
        <!-- ></template> -->
        <template v-slot:top>
          <v-dialog v-model="dialog" dense max-width="50%">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedStudent.student_name"
                        label="Name"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.student_date_added"
                        label="Date Added"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.email"
                        label="Email"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.pre_complete"
                        label="Pre-Survey Complete"
                        :rules="[rules.yyyymmddRule]"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.pre_reward"
                        label="Pre-Survey Reward"
                        :rules="[rules.yyyymmddRule]"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.ongoing_complete"
                        label="Ongoing Survey Complete"
                        :rules="[rules.yyyymmddRule]"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.ongoing_reward"
                        label="Ongoing Survey Reward(s)"
                        :rules="[rules.yyyymmddRule]"
                      ></v-text-field>
                      <!-- <v-text-field -->
                      <!--   v-model="editedStudent.interview_invite" -->
                      <!--   label="Interview Invite" -->
                      <!--   :rules="[rules.yyyymmddRule]" -->
                      <!-- ></v-text-field> -->
                      <!-- <v-text-field -->
                      <!--   v-model="editedStudent.interview_schedule" -->
                      <!--   label="Interview Scheduled" -->
                      <!--   :rules="[rules.yyyymmddRule]" -->
                      <!-- ></v-text-field> -->
                      <!-- <v-text-field -->
                      <!--   v-model="editedStudent.interview_complete" -->
                      <!--   label="Interview Complete" -->
                      <!--   :rules="[rules.yyyymmddRule]" -->
                      <!-- ></v-text-field> -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editStudent(item)"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
      <v-row class="mb-2">
        <v-btn color="success" @click="downloadTasks">Download Task List</v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import ChecklistNavDrawer from "../components/ChecklistNavDrawer.vue";
// import CheckExists from "../components/CheckExists.vue";
import CheckExistsMultiple from "../components/CheckExistsMultiple.vue";
import CustomDateCheck from "../components/CustomDateCheck.vue";
// import DateInput from "../components/DateInput.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "ChecklistStudent",
  components: {
    Loading,
    ChecklistNavDrawer,
    // CheckExists,
    CheckExistsMultiple,
    CustomDateCheck,
    // DateInput,
  },
  data: () => ({
    isLoading: false,
    uploadSuccess: false,
    rowsInserted: 0,
    includeOldSurveys: false,
    showFullChecklist: false,
    checklistStudent: [],
    previousPreReward: null,
    headers: [
      { text: "Name", value: "student_name" },
      { text: "Date", value: "student_date_added" },
      { text: "Email", value: "email" },
      { text: "Complete", value: "pre_complete" },
      { text: "Reward", value: "pre_reward" },
      { text: "Compete", value: "ongoing_complete" },
      { text: "Reward", value: "ongoing_reward" },
      // { text: "Invite", value: "interview_invite" },
      // { text: "Schedule", value: "interview_schedule" },
      // { text: "Complete", value: "interview_complete" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    rules: {
      yyyymmddRule: (value) => {
        const pattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}/;
        return pattern.test(value) || "Date format: YYYY-MM-DD";
      },
    },
    dialog: false,
    editedStudent: {
      student_name: "",
      student_date_added: "",
      email: "",
      pre_complete: "",
      pre_reward: "",
      ongoing_complete: "",
      ongoing_reward: "",
      // interview_invite: "",
      // interview_schedule: "",
      // interview_complete: "",
    },
    defaultStudent: {
      student_name: "",
      student_date_added: "",
      email: "",
      pre_complete: "",
      pre_reward: "",
      ongoing_complete: "",
      ongoing_reward: "",
      // interview_invite: "",
      // interview_schedule: "",
      // interview_complete: "",
    },
    editedIndex: -1,
    unwatches: [],
  }),
  computed: {
    currentDate() {
      return this.formatDate(new Date());
    },
  },
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      await Promise.all([
        this.getApiData(
          "/checklist_student",
          "checklistStudent",
          this.surveyStartFilter,
          this.nameSort
        ),
      ]);
      // Create a watcher on each row of the table
      this.checklistStudent.forEach((val) => {
        const unwatch = this.$watch(() => val, this.dataTableItemsChange, {
          deep: true,
        });
        this.unwatches.push(unwatch);
      });
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    sequenceSort: function (a, b) {
      return a.sequence - b.sequence;
    },
    nameSort: function (a, b) {
      if (a.student_name > b.student_name) {
        return 1;
      }
      if (a.student_name < b.student_name) {
        return -1;
      }
      return 0;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    surveyStartFilter: function (i) {
      let preRewardDue =
        this.showFullChecklist ||
        (i.pre_complete &&
          i.pre_complete.length &&
          (!i.pre_reward || i.pre_reward.length === 0));

      if (this.includeOldSurveys && preRewardDue) {
        return true;
      } else {
        return i.student_date_added > "2023-01-01" && preRewardDue;
      }
    },
    editStudent: function (s) {
      this.editedIndex = this.checklistStudent.indexOf(s);
      this.editedStudent = Object.assign({}, s);
      this.dialog = true;
    },
    close: function () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedStudent = Object.assign({}, this.defaultStudent);
        this.editedIndex = -1;
      });
    },
    changeOldSurvey: async function () {
      this.isLoading = true;
      this.unwatches.forEach((e) => {
        e;
      });
      this.checklistStudent = [];
      await this.getApiData(
        "/checklist_student",
        "checklistStudent",
        this.surveyStartFilter,
        this.nameSort
      );
      this.unwatches = [];
      this.checklistStudent.forEach((val) => {
        const unwatch = this.$watch(() => val, this.dataTableItemsChange, {
          deep: true,
        });
        this.unwatches.push(unwatch);
      });
      this.isLoading = false;
    },
    // Update the data based on the new row data
    dataTableItemsChange: async function (val) {
      let rowsInserted = 0;
      if (val.pre_reward && val.pre_reward.length) {
        // Add
        const payload = [
          {
            student_name: val.student_name,
            student_date_added: this.formatDate(val.student_date_added),
            item: "pre-survey reward",
            date_completed: val.pre_reward,
          },
        ];
        await axios
          .post("checklist/keys/u/", payload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err));
      } else {
        // Remove
        const payload = [
          {
            student_name: val.student_name,
            student_date_added: this.formatDate(val.student_date_added),
            item: "pre-survey reward",
            date_completed: this.previousPreReward,
          },
        ];
        await axios
          .delete("checklist/keys/u/", {
            data: payload,
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("inserted") > -1
                ) {
                  rowsInserted = rowsInserted + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err));
        this.previousPreReward = null;
      }
      // if (rowsInserted > 0) {
      //   this.rowsInserted = rowsInserted;
      //   this.uploadSuccess = true;
      //   this.fileName = null;
      //   this.csvData = [];
      //   this.csvErrors = [];
      //   this.header = [];
      //   this.rawData = null;
      // }
    },
    downloadTasks: function () {
      // pre-survey rewards
      let rewards = [];
      this.checklistStudent.forEach((e) => {
        if (
          e.pre_complete &&
          e.pre_complete.length &&
          (!e.pre_reward || e.pre_reward.length === 0)
        ) {
          const reward = {
            student_name: e.student_name,
            email: e.email,
            survey_completed: e.pre_complete,
            task: "pre-survey reward",
          };
          rewards.push(reward);
        }
      });
      let csvData = this.$papa.unparse(rewards);
      const ts = new Date();
      const dte = this.formatDate(ts);
      this.$papa.download(csvData, `task_list_${dte}`);
    },
    save: function () {
      if (this.editedIndex > -1) {
        Object.assign(
          this.checklistStudent[this.editedIndex],
          this.editedStudent
        );
      } else {
        this.checklistStudent.push(this.editedStudent);
      }
      // TODO: write editedStudent to database
      this.close();
    },
    // Assumes UTC because Postgres dates (no times) come in with
    // a "T00:00:00Z" timestamp, so they need to be interpreted as UTC
    formatDate: function (d) {
      let ts = new Date(d);
      return `${ts.getUTCFullYear()}-${(ts.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ts.getUTCDate().toString().padStart(2, "0")}`;
    },
    // Assumes UTC because Postgres dates (no times) come in with
    // a "T00:00:00Z" timestamp, so they need to be interpreted as UTC
    formatDateNoBreak: function (d) {
      if (d) {
        let ts = new Date(d);
        return `${ts.getUTCFullYear()}‑${(ts.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}‑${ts.getUTCDate().toString().padStart(2, "0")}`;
      } else {
        return "";
      }
    },
    formatMentors: function (m) {
      let collapse = "";
      if (m && m.length) {
        m.forEach((mentor) => {
          if (mentor && mentor.length) {
            collapse = `${collapse}, ${mentor}`;
          }
        });
        collapse = collapse.replace(/^, /, "");
      }

      return collapse;
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
  },
};
</script>

<style scoped>
tr.center > th {
  text-align: center !important;
}
</style>
