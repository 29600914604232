<template>
  <SurveySingleSurvey />
</template>

<script>
import SurveySingleSurvey from "../components/SurveySingleSurvey.vue";

export default {
  name: "SurveySingleView",

  components: {
    SurveySingleSurvey,
  },
};
</script>
