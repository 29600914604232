<template>
  <StudentList />
</template>

<script>
import StudentList from "../components/StudentList.vue";

export default {
  name: "StudentListView",

  components: {
    StudentList,
  },
};
</script>
