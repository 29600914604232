<template>
  <v-container>
    <!-- SNACK -->
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="2000"
      v-model="uploadSuccess"
    >
      <div class="text-body-1">
        uploaded to server ({{ rowsInserted }} rows)
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="uploadSuccess = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <StudentNavDrawer />
    <!-- SNACK -->
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="2000"
      v-model="uploadSuccess"
    >
      <div class="text-body-1">
        uploaded to server ({{ rowsInserted }} rows)
      </div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="uploadSuccess = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-container>
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
      />
    </v-container>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="nameVariant"
        :items-per-page="25"
        :footer-props="{ itemsPerPageOptions: [25, 50, 100, -1] }"
        :loading="!nameVariant || !nameVariant.length"
        dense
      >
        <template v-slot:[`item.date_added`]="{ item }">
          {{ formatDate(item.date_added) }}
        </template>
        <template v-slot:top>
          <v-dialog v-model="dialog" dense max-width="50%">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="editedStudent.name_variant"
                        label="Name Variant"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.name"
                        label="Name"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedStudent.notes"
                        label="Notes"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" dense max-width="50%">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this mapping?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteConfirm"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editStudent(item)"
            >mdi-pencil</v-icon
          >
          <v-icon small @click="deleteStudent(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import StudentNavDrawer from "../components/StudentNavDrawer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "StudentList",
  components: {
    Loading,
    StudentNavDrawer,
  },
  data: () => ({
    isLoading: false,
    nameVariant: [],
    headers: [
      { text: "Name Variant", value: "name_variant" },
      { text: "Name", value: "name" },
      { text: "Date Added", value: "date_added" },
      { text: "Notes", value: "notes" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dialog: false,
    dialogDelete: false,
    editedStudent: {
      name_variant: "",
      name: "",
      date_added: "",
      notes: "",
    },
    defaultStudent: {
      name_variant: "",
      name: "",
      date_added: "",
      notes: "",
    },
    editedIndex: -1,
    uploadSuccess: false,
    rowsInserted: 0,
  }),
  computed: {},
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      await Promise.all([
        this.getApiData(
          "/name_variant",
          "nameVariant",
          this.supersededFilter,
          this.nameSort
        ),
      ]);
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    sequenceSort: function (a, b) {
      return a.sequence - b.sequence;
    },
    nameSort: function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    editStudent: function (s) {
      this.editedIndex = this.nameVariant.indexOf(s);
      this.editedStudent = Object.assign({}, s);
      this.dialog = true;
    },
    deleteStudent: function (s) {
      this.editedIndex = this.nameVariant.indexOf(s);
      this.editedStudent = Object.assign({}, s);
      this.dialogDelete = true;
    },
    close: function () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedStudent = Object.assign({}, this.defaultStudent);
        this.editedIndex = -1;
      });
    },
    save: async function () {
      if (this.editedIndex > -1) {
        Object.assign(this.nameVariant[this.editedIndex], this.editedStudent);
      } else {
        this.nameVariant.push(this.editedStudent);
      }
      let dte = new Date();
      let payload = [
        {
          name_variant: this.editedStudent.name_variant,
          name: this.editedStudent.name,
          date_added: `${dte.getFullYear()}-${(dte.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${dte.getDate().toString().padStart(2, "0")}`,
          notes:
            this.editedStudent.notes && this.editedStudent.notes.length
              ? this.editedStudent.notes
              : null,
        },
      ];
      let rowsInserted = 0;
      await axios
        .post("name_variant/keys/u/", payload, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status === 200) {
            for (let i in response.data.message) {
              if (
                response.data.status[i] != "error" &&
                response.data.message[i].search("inserted") > -1
              ) {
                rowsInserted = rowsInserted + 1;
              } else {
                console.log("error submitting: " + response.data.message[i]);
              }
            }
          } else {
            console.log("post fail, code = " + response.status);
          }
        })
        .catch((err) => console.log("error posting: ", err));
      if (rowsInserted > 0) {
        this.rowsInserted = rowsInserted;
        this.uploadSuccess = true;
        await this.getApiData(
          "/name_variant/",
          "nameVariant",
          this.supersededFilter,
          this.nameSort
        );
      }
      this.close();
    },
    closeDelete: function () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedStudent = Object.assign({}, this.defaultStudent);
        this.editedIndex = -1;
      });
    },
    deleteConfirm: function () {
      // TODO: delete editedStudent from database
      console.log(
        `TODO: delete nameVariant.serial == ${
          this.nameVariant[this.editedIndex].serial
        }`
      );
      this.nameVariant.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
    // Assumes UTC because Postgres dates (no times) come in with
    // a "T00:00:00Z" timestamp, so they need to be interpreted as UTC
    formatDate: function (d) {
      let ts = new Date(d);
      return `${ts.getUTCFullYear()}-${(ts.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0")}-${ts.getUTCDate().toString().padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped></style>
