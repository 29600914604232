<template>
  <v-container>
    <SurveyNavDrawer />
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true" />
    <v-snackbar
      app
      top
      right
      multiLine
      color="success"
      timeout="1000"
      v-model="uploadSuccess"
    >
      <div class="text-body-1">Survey response updated</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="uploadSuccess = false"
          >Close</v-btn
        ></template
      >
    </v-snackbar>
    <v-container>
      <v-row class="mb-2">
        <v-select
          class="mb-2"
          label="Survey"
          v-model="surveyID"
          :items="survey"
          item-text="survey_title"
          item-value="survey_id"
          outlined
          dense
          hide-details
          @change="changeSurvey"
        ></v-select>
      </v-row>
      <v-row class="mb-2">
        <v-switch
          v-model="includeOldSurveys"
          inset
          :label="`Include older surveys`"
          @change="changeOldSurvey"
        ></v-switch>
      </v-row>
      <v-row class="mb-2">
        <v-autocomplete
          class="mb-2"
          label="Respondent"
          v-model="respondentID"
          :items="surveyResponseStub"
          item-text="stub"
          item-value="respondent_id"
          outlined
          dense
          hide-details
          @change="changeRespondent"
        ></v-autocomplete>
      </v-row>
      <div v-if="response.length">
        <v-row class="mt-2 mb-3">
          <v-select
            class="mb-2"
            label="Status"
            v-model="surveyResponseReviewStatus"
            :items="refSurveyResponseStatus"
            item-text="title"
            item-value="code"
            outlined
            dense
            hide-details
            @change="changeSurveyResponseReviewStatus"
          ></v-select>
        </v-row>
        <div class="border">
          <label>Survey Responses</label>
          <div
            v-for="item in questionsAnswers"
            v-bind:key="item.question_number"
          >
            <p class="question">
              <span class="question-number">{{ item.question_number }}</span>
              {{ item.question }}
            </p>
            <p class="answer">{{ item.response_str }}</p>
          </div>
        </div>
      </div>
    </v-container>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import SurveyNavDrawer from "../components/SurveyNavDrawer.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import localforage from "localforage";

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_URL;
axios.defaults.withCredentials = true;

export default {
  name: "SurveyFullSurvey",
  components: {
    Loading,
    SurveyNavDrawer,
  },
  data: () => ({
    version: "2022-12-23 07:54:38",
    isLoading: false,
    valid: true,
    includeOldSurveys: false,
    surveyID: null,
    respondentID: null,
    surveyResponseReviewStatus: null,
    reviewStatusFilter: null, // ours
    siteFilter: null,
    surveyStatusFilter: null, // surveymonkey
    survey: [],
    surveyResponse: [],
    collector: [],
    question: [],
    response: [],
    student: [],
    rowsUpdated: 0,
    uploadSuccess: false,
    refSurveyResponseStatus: [], // our choices
    refState: [],
    surveyResponseStatus: ["completed", "disqualified", "partial"], // surveymonkey
  }),
  computed: {
    surveyResponseStub: function () {
      let srs = structuredClone(this.surveyResponse);
      srs.forEach(
        (obj) => (obj.stub = `${obj.name} (${obj.email}) ${obj.date_modified}`)
      );
      return srs
        .filter(
          (obj) =>
            !this.reviewStatusFilter || obj.status === this.reviewStatusFilter
        )
        .filter(
          (obj) => !this.siteFilter || obj.collector_id === this.siteFilter
        )
        .filter(
          (obj) =>
            !this.surveyStatusFilter ||
            obj.response_status === this.surveyStatusFilter
        );
    },
    cleanedCollector: function () {
      let c = structuredClone(this.collector);
      c.forEach(
        (obj) => (obj.collector_name = obj.collector_name.replace("Post ", ""))
      );
      return c;
    },
    questionsAnswers: function () {
      let qa = [];
      for (let obj of this.response) {
        let question = this.question.filter(
          (e) => e.question_number === obj.question_number
        )[0];
        obj.question = question.question;
        if (question.data_type == "factor" && question.response_levels > 1) {
          obj.response_str = `${obj.response} (${obj.response_level} / ${question.response_levels})`;
        } else {
          obj.response_str = obj.response;
        }
        qa.push(obj);
      }
      return qa;
    },
  },
  created() {
    localforage.config({
      name: "nasa-stem-survey",
      version: 1,
    });
  },
  async mounted() {
    this.isLoading = true;
    await axios.get("/logged_in").then((response) => {
      if (response.data == "access denied") {
        this.$store.commit("logout");
      } else {
        this.$store.commit("auth_success", response.data.Username);
      }
    });
    if (this.$store.getters.username && this.$store.getters.username.length) {
      await Promise.all([
        this.getApiData(
          "/ref_survey_response_status/",
          "refSurveyResponseStatus",
          this.supersededFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/ref_state/",
          "refState",
          this.supersededFilter,
          this.sequenceSort
        ),
        this.getApiData(
          "/survey/",
          "survey",
          this.surveyStartFilter,
          this.surveyIDSort
        ),
        this.getApiData(
          "/student/",
          "student",
          this.studentDateAddedFilter,
          this.surveyIDSort
        ),
        this.getLocalSurveyID(),
      ]);
    }
    this.isLoading = false;
    if (
      this.$route.params.respondent_id &&
      this.$route.params.respondent_id.length
    ) {
      this.respondentID = parseInt(this.$route.params.respondent_id);
      await this.getApiData(
        `/survey_response/respondent_id/${this.respondentID}`,
        "surveyResponse",
        () => {
          return 1;
        },
        () => {
          return 1;
        }
      );
      this.surveyID = this.surveyResponse[0].survey_id;
      this.response = [];
      await Promise.all([
        this.getApiData(
          `/question/survey_id/${this.surveyID}`,
          "question",
          this.supersededFilter,
          this.questionNumberSort
        ),
        this.getApiData(
          `/collector/survey_id/${this.surveyID}`,
          "collector",
          this.supersededFilter,
          this.dateModifiedSort
        ),
      ]);
      this.changeRespondent();
    }
  },
  methods: {
    sequenceSort: function (a, b) {
      return a.sequence - b.sequence;
    },
    surveyIDSort: function (a, b) {
      return a.survey_id - b.survey_id;
    },
    questionNumberSort: function (a, b) {
      return a.question_number - b.question_number;
    },
    dateModifiedSort: function (a, b) {
      if (a.date_modified > b.date_modified) {
        return -1;
      } else if (a.date_modified < b.date_modified) {
        return 1;
      } else {
        return 0;
      }
    },
    supersededFilter: function (a) {
      return !a.superseded;
    },
    surveyStartFilter: function (i) {
      if (this.includeOldSurveys) {
        return true;
      } else {
        return i.start_date > "2023-01-01";
      }
    },
    studentDateAddedFilter: function (a) {
      const dateAdded = new Date(a.date_added);
      const newDate = new Date("2023-01-01");

      if (this.includeOldSurveys || dateAdded > newDate) {
        return true;
      } else {
        return false;
      }
    },
    getApiData: async function (
      endpoint,
      varName,
      filterFunction,
      sortFunction
    ) {
      await axios
        .get(endpoint)
        .then(
          (response) =>
            (this[varName] = response.data
              .filter(filterFunction)
              .sort(sortFunction))
        );
    },
    changeOldSurvey: async function () {
      this.isLoading = true;
      this.survey = [];
      await this.getApiData(
        "/survey/",
        "survey",
        this.surveyStartFilter,
        this.surveyIDSort
      );
      this.isLoading = false;
    },
    changeSurvey: async function () {
      this.isLoading = true;
      this.response = [];
      await Promise.all([
        this.getApiData(
          `/survey_response/survey_id/${this.surveyID}`,
          "surveyResponse",
          this.supersededFilter,
          this.dateModifiedSort
        ),
        this.getApiData(
          `/question/survey_id/${this.surveyID}`,
          "question",
          this.supersededFilter,
          this.questionNumberSort
        ),
        this.getApiData(
          `/collector/survey_id/${this.surveyID}`,
          "collector",
          this.supersededFilter,
          this.dateModifiedSort
        ),
        localforage
          .setItem("surveyID", this.surveyID)
          .catch((e) =>
            console.log(`error setting surveyID to localforage: ${e}`)
          ),
      ]);
      this.isLoading = false;
    },
    changeRespondent: async function () {
      this.response = [];
      this.isLoading = true;
      await this.getApiData(
        `/response/respondent_id/${this.respondentID}`,
        "response",
        this.supersededFilter,
        this.questionNumberSort
      );
      this.surveyResponseReviewStatus = this.surveyResponse.filter(
        (obj) => obj.respondent_id === this.respondentID
      )[0].status;
      this.isLoading = false;
    },
    changeSurveyResponseReviewStatus: async function () {
      // update survey_response.status from this.surveyResponseReviewStatus
      // add a student to the student table if necessary
      // WARNING: does not delete students when status is changed from "valid"
      if (
        this.surveyResponseReviewStatus &&
        this.surveyResponseReviewStatus.length
      ) {
        // Update
        const surveyResponsePayload = [
          {
            survey_id: this.surveyID,
            respondent_id: this.respondentID,
            status: this.surveyResponseReviewStatus,
          },
        ];
        console.log(surveyResponsePayload);
        let rowsUpdated = 0;
        await axios
          .post("survey_response/update_status/", surveyResponsePayload, {
            headers: { "Content-Type": "application/json" },
          })
          .then((response) => {
            if (response.status === 200) {
              for (let i in response.data.message) {
                if (
                  response.data.status[i] != "error" &&
                  response.data.message[i].search("updated") > -1
                ) {
                  rowsUpdated = rowsUpdated + 1;
                } else {
                  console.log("error submitting: " + response.data.message[i]);
                }
              }
            } else {
              console.log("post fail, code = " + response.status);
            }
          })
          .catch((err) => console.log("error posting: ", err));
        let studentPayload = [];
        if (this.surveyResponseReviewStatus === "valid") {
          const currentSurveyResponse = this.surveyResponse.filter(
            (sr) =>
              sr.survey_id === this.surveyID &&
              sr.respondent_id === this.respondentID
          )[0];
          const currentStudent = this.student.filter(
            (s) => s.name === currentSurveyResponse.name
          );
          if (currentStudent.length === 0) {
            // add a student
            const schoolQuestion = this.questionsAnswers.filter(
              (qa) =>
                qa.question.search(/contact.*university or college$/i) > -1
            );
            const school = schoolQuestion.length
              ? schoolQuestion[0].response
              : null;
            const stateQuestion = this.questionsAnswers.filter(
              (qa) => qa.question.search(/state or jurisdiction.*degree/i) > -1
            );
            const state = stateQuestion.length
              ? stateQuestion[0].response
              : null;
            const stateChoice = this.refState.filter((s) => s.title === state);
            const stateChoiceRef = stateChoice.length
              ? stateChoice[0].code
              : null;
            const degreeQuestion = this.questionsAnswers.filter(
              (qa) =>
                qa.question.search(/type of degree.*currently pursuing/i) > -1
            );
            const degree = degreeQuestion.length
              ? degreeQuestion[0].response
              : null;
            const schoolYearQuestion = this.questionsAnswers.filter(
              (qa) =>
                qa.question.search(
                  /(current class standing|years are you into your graduate program)/i
                ) > -1
            );
            const schoolYear = schoolYearQuestion.length
              ? schoolYearQuestion[0].response
              : null;
            // convert degree and schoolYear into ref table values
            const degreeChoices = [
              {
                surveyChoice: "Associates degree",
                refTable: "community college",
              },
              {
                surveyChoice: "Undergraduate or bachelor’s degree",
                refTable: "undergraduate",
              },
              { surveyChoice: "Master’s degree", refTable: "masters" },
              { surveyChoice: "PhD or doctorate", refTable: "phd" },
            ];
            const levelChoices = [
              { surveyChoice: "Three years", refTable: "third" },
              { surveyChoice: "Senior", refTable: "senior" },
              { surveyChoice: "First year", refTable: "first" },
              { surveyChoice: "Four years", refTable: "fourth" },
              { surveyChoice: "Sophomore", refTable: "sophomore" },
              { surveyChoice: "Five or more years", refTable: "fifth" },
              { surveyChoice: "Two years", refTable: "second" },
              { surveyChoice: "One year", refTable: "first" },
              { surveyChoice: "Junior", refTable: "junior" },
            ];
            const degreeChoice = degreeChoices.filter(
              (dc) => dc.surveyChoice === degree
            );
            const degreeChoiceRef = degreeChoice.length
              ? degreeChoice[0].refTable
              : null;
            const levelChoice = levelChoices.filter(
              (lc) => lc.surveyChoice === schoolYear
            );
            const levelChoiceRef = levelChoice.length
              ? levelChoice[0].refTable
              : null;
            studentPayload.push({
              name: currentSurveyResponse.name,
              email: currentSurveyResponse.email,
              school: school,
              state: stateChoiceRef,
              school_level: degreeChoiceRef,
              school_year: levelChoiceRef,
              date_added: currentSurveyResponse.date_modified.substr(0, 10),
              status: "unmatched survey response",
            });

            console.log(studentPayload);
            await axios
              .post("student/keys/u/", studentPayload, {
                headers: { "Content-Type": "application/json" },
              })
              .then((response) => {
                if (response.status === 200) {
                  for (let i in response.data.message) {
                    if (
                      response.data.status[i] != "error" &&
                      response.data.message[i].search("inserted") > -1
                    ) {
                      rowsUpdated = rowsUpdated + 1;
                      this.student.push(studentPayload[0]);
                    } else {
                      console.log(
                        "error submitting: " + response.data.message[i]
                      );
                    }
                  }
                } else {
                  console.log("post fail, code = " + response.status);
                }
              })
              .catch((err) => console.log("error posting: ", err));
          }
        }
        if (rowsUpdated > 0) {
          this.rowsUpdated = rowsUpdated;
          this.uploadSuccess = true;
        }
      }
    },
    getLocalSurveyID: async function () {
      await localforage
        .getItem("surveyID")
        .then((v) => {
          if (v) {
            this.surveyID = v;
            this.changeSurvey();
          }
        })
        .catch((e) => console.log(`error loading  from localforage: ${e}`));
    },
    logout: function () {
      this.$store.commit("logout");
    },
  },
};
</script>

<style>
.question {
  font-weight: 400;
}
.question-number {
  font-weight: 400;
  font-style: italic;
  font-size: 75%;
  margin-left: -1em;
  padding-right: 1em;
}
.answer {
  font-weight: 800;
}
.border {
  border-radius: 4px;
  padding-left: 1em;
  padding-right: 1em;
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.border > label {
  position: relative;
  top: -14px;
  left: 0px;
  color: rgba(0, 0, 0, 0.6);
  background-color: white;
  font-size: 80%;
}
</style>
